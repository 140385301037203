.chat_transfer{
    position: absolute;
    background-color: white;
    border: 1px solid $border-color-light;
    bottom: 100%;
    margin-bottom: 0.5rem;
    border-radius: $border-radius-medium;
    box-shadow: 0px 0px 15px rgba(black, 0.1);
    width: 300px;
    overflow: hidden;
    animation: fadeIn 0.3s;

    .widget_title{
        border-bottom: 1px solid $border-color-light;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        background-color: $offwhite;
        cursor: default;
    }
}

.chat_transfer_list{
    height: 280px;
    overflow-y: auto;
}

.chat_transfer_list__item{
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid $border-color-light;
    cursor: pointer;
    transition: background 0.1s ease-in-out;

    &[disabled]{
        opacity: 0.5;
        cursor: not-allowed;
        &:hover{
            background: none;
        }
    }

    &:hover{
        background-color: darken(white, 4%);
    }

    &:last-child{
        border-bottom: 0px;
    }

    .online_indicator{
        margin: 0px 1rem;
    }

    .agent_profile_avatar{
        margin: 0px 0.5rem;
    }

    .avatar-container{
        margin: 0px $margin-small;
    }
}

.chat_transfer_list__item__details{
    flex-grow: 1;

    .status_display{
        font-size: 0.8rem;
        .status_icon{
            font-size: 1rem;
            margin-right: 0.1rem;
        }
    }
}

.queues_title{
    font-weight: 500;
}

.queues_description{
    font-size: 0.8rem;
    color: #888;
}

.transfer_to_user{
    font-size: 1.2rem;
    background-color: darken($offwhite, 4%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0px 0.5rem;
}

.chat_transfer_search{
    input{
        border: none;
        border-bottom: 1px solid $border-color-light;
        padding: 0.8rem 1rem;
        margin-bottom: 0px;
    }
}

.chat_transfer_list__subtitle{
    display: flex;
    align-items: center;
    background-color: $offwhite;
    padding: 0.3rem 1rem;
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: bold;
    color: darken($offwhite, 60%);
}