.drag_file_upload{
    width: 100%;
    max-width: unset !important;
    border-color: $border-color !important;
    padding: $padding-large !important;
    height: fit-content !important;
    svg path{
        fill: $charcoal !important;
    }

    svg{
        
        margin-right: $margin-medium !important;
    }
}

.file_upload_input_preview{
    .file_upload_input_preview_doc_preview{
        position: relative;
        width: fit-content;
        button{
            position: absolute;
            width: 20px;
            height: 20px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 50%;
            background-color: $warning-red;
            top: -2px;
            right: -2px;
            border: none;
            color: white;
            cursor: pointer;

            svg{
                margin-right: 0px;
                font-size: 14px;
            }
        }
    }

    img{
        max-width: 100px;
        max-height: 100px;
        border: 1px solid $border-color;
        border-radius: 8px;
        margin-bottom: $margin-medium;
    }
}