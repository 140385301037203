
.agent_profile_avatar{
    @extend .chat_message_avatar;
    flex-shrink: 0;
    background: radial-gradient(circle, lighten($secondary-color, 20%) 0%, $secondary-color 100%);
    .agent_initials{
        @extend .chat_message_avatar__initials;
    }

    img{
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        object-position: 50%;
        max-height: unset !important;
        max-width: unset !important;
        background-color: $offwhite;
    }

    .preloader{
        z-index: 22;
        background: rgba($grey, 0.75);
    }
}

.avatar-container{
    position: relative;
    display: flex;
    flex-shrink: 0;

    &.disabled{
        .agent_profile_avatar::after{
            content: "";
            position: absolute;
            left: 0%;
            top: 0%;
            width: 100%;
            height: 100%;
            background-color: rgba($light-grey, 0.8);
        }
    }

    &.status_shown .status_display{
        position: absolute;
        display: block;
        bottom: -5%;
        right: 10%;
        background-color: white;
        border-radius: 50%;
        font-size: 0.6rem !important;
        line-height: 0.6rem !important;
        .status_icon{
            font-size: 170% !important;
        }
        svg{
            margin: 0px;
        }
    }

    .popup_box{
        top: unset;
        bottom: 0;
        left: 0;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        width: 350px;

        .agent_profile_avatar{
            margin: 0;
            margin-right: 0.5rem;
        }

        .status_display{
            font-size: 0.8rem !important;
            margin-bottom: 0.8rem;
            .status_icon{
                margin-right: 0.1rem !important;
            }
        }
    }
}

.agent_popup_details__name{
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 0.1rem;
}

.agent_popup_details__detail{
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    text-decoration: none;

    a{
        text-decoration: none;
    }

    svg{
        font-size: 1rem;
        margin-right: 0.3rem;
        color: $primary-color;
    }
}