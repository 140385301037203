.meeting_room{
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
    background-position: 50%;
    background-size: cover;
}

.meeting_loader{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: $padding-large 0px;
}

.meeting_room_panel_container{
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $mobile-breakpoint) {

        .select-item.speakers{
            display: none;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) and (min-height: 500px){
        .meeting_room__content_box__title{
            display: none;
        }
    }

    @media screen and (max-height: 500px) and (max-width: $mobile-breakpoint){
        .meeting_room__content_box__title{
            align-items: flex-start;
            flex-direction: column-reverse;
            img{
                margin-bottom: $margin-medium;
                max-width: 200px;
            }
        }
    }

    @media screen and (max-height: 500px) and (max-width: $mobile-breakpoint){
        .col-6{
            width: 50% !important;
            flex-direction: column;
        }
    }
}

.meeting_room__content_box__title__mobile{
    @extend .meeting_room__content_box__title;
    display: none !important;
    @media screen and (max-width: $mobile-breakpoint) and (min-height: 500px) {
        display: flex !important;
        flex-direction: column-reverse;
        font-weight: 500 !important;
        border-bottom: 0px !important;

        .meeting_room__settings_logo{
            max-width: unset;
            width: 90%;
            max-width: 150px;
            margin-bottom: $margin-small;
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .join_meeting_button{
        width: fit-content !important;
        padding: 0.8rem $padding-medium;
    }
}

.meeting_room.agent_view{
    background-color: rgba(black, 0.75);
    .meeting_room_panel{
        padding: 4rem 3rem !important;
        width: 95% !important;
        max-width: 1000px !important;
        border-radius: $border-radius-small;
        box-shadow: 0px 0px 30px rgba(black, 0.75);
    }
}

.meeting_room__display_name_logo{
    max-width: 270px;
    margin-bottom: $margin-medium;
}

.meeting_room_close{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    cursor: pointer;
    font-size: 1.2rem;
    border-bottom-left-radius: $border-radius-large;
    &:hover{
        background-color: darken(white, 2%);
    }
}

.meeting_join_page_settings{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.meeting_room_panel{
    @extend .modal;
    position: relative;
    display: flex;
    width: 95% !important;
    align-items: center;
    max-width: $contained-width !important;
    padding: 2.2rem;
    overflow: hidden;
    background-color: white;
    border-radius: $border-radius-small;

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        border: none;
        flex-direction: column;
        flex-grow: 1;
        padding: 0.5rem;
        margin: $margin-medium;

        .flex-grow{
            width: 100%;
        }

        .container{
            flex-grow: 1;
        }
    }

    @media screen and (max-height: 500px) and (max-width: $mobile-breakpoint){
        .container{
            flex-direction: row-reverse !important;
        }
    }
}

.meeting_join_page_settings {
    display: flex;
    flex-direction: column;
    padding: 0px $padding-large;

    @media screen and (max-width: $mobile-breakpoint) {
        flex-grow: 1;
        padding: $padding-medium;
        flex-direction: row;
        justify-content: center;

        .local_settings{
            display: none;
        }
    }
}

.video_preview_card{
    position: relative;
    background-color: $charcoal;
    border-radius: $border-radius-large;
    overflow: hidden;
    box-shadow: -5px 10px 15px rgba(black, 0.3);

    .meeting_room_call__local_video_preview{
        position: absolute;
        height: 100%;
    }

    @media screen and (max-width: $mobile-breakpoint) and (min-height: 500px){
        display: block;
        margin: auto;
        width: 90vw;
        box-shadow: 0px 10px 15px rgba(black, 0.3);
        margin-bottom: $margin-medium;
        .meeting_room_call__local_video_preview{
            width: 100%;
        }

        video{
            overflow: hidden !important;
            box-shadow: 15px 15px 30px rgba(black, 0.1) !important;
            width: 100% !important;
            height: 100% !important;
            left: 0;
            object-position: 50% !important;
            object-fit: cover !important;
            border-radius: $border-radius-medium;
        }
    }

    @media screen and (max-height: 500px) and (max-width: $mobile-breakpoint){
        width: 35vw;
        margin: auto;
        border-radius: $border-radius-medium;
        overflow: hidden;
        box-shadow: 0px 10px 15px rgba(black, 0.3);

        video{
            overflow: hidden !important;
            box-shadow: 15px 15px 30px rgba(black, 0.1) !important;
            width: 100% !important;
            height: 100% !important;
            left: 0;
            object-position: 50% !important;
            object-fit: cover !important;
            border-radius: $border-radius-medium;
        }
    }
}

.meeting_room__content_box__sub_title{
    font-weight: 700;
    text-align: center;
    margin-bottom: $margin-medium;
    font-size: 1.2rem;
}

.video_preview_card_icon{
    position: relative;
    height: 350px;
    color: rgba(white, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    background-color: rgba(black, 0.15);

    @media screen and (max-width: $mobile-breakpoint) and (min-height: 500px) {
        height: 90vw;
    }

    @media screen and (max-height: 500px) and (max-width: $mobile-breakpoint){
        height: 35vw;
    }
}

.local_settings{
    margin-bottom: $margin-large;
}

.meeting_room__content_box{
    width: 95%;
    max-width: 500px;
    padding: $padding-large;
    background-color: white;

    .alert_box{
        text-align: center;
    }
}

.meeting_room__content_box__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    margin-bottom: $margin-medium;
    color: $grey;
    padding-bottom: $padding-small;
    border-bottom: 1px solid $border-color-light;
}

.meeting_room__settings_logo{
    max-width: 100px;
}

.meeting_room__content_box__body{
    color: #777;
    text-align: center;
    margin-bottom: $margin-medium;
}

.meeting_room_form{
    margin-bottom: $margin-medium;
    input{
        border-radius: $border-radius-small;
    }

    &.display_name{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    button{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: $padding-medium $padding-large;
        border: 0px;
        background-color: $primary-color;
        color: white;
        font-weight: 500;
        font-family: inherit;
        border-radius: $border-radius-small;
        outline: 0px;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        &[disabled]{
            opacity: 0.8;
            cursor: default;
            &:hover{
                background-color: $primary-color !important;
            }
        }

        &:hover{
            background-color: darken($primary-color, 5%);
        }
    }
}

.video_audio_settings{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem $padding-medium;

    label{
        margin-bottom: 0px;
    }

    .toggle_box{
        margin-right: $margin-medium;
        &:last-child{ margin-right: 0px; }
        color: white;
    }
    
    @media screen and (max-width: $mobile-breakpoint) {
        position: absolute;
        width: 100%;
        bottom: 0;
        background: linear-gradient(transparent, rgba(black, 0.6) 70%);
        border-radius: $border-radius-medium;

        .toggle_box{
            flex-direction: column;
            svg{
                margin-bottom: $margin-small;
            }
        }
    }
}
