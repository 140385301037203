.incoming_meeting{
    position: relative;
    padding: $padding-medium;
    border: 1px solid $border-color;
    border-radius: $border-radius-medium;
    margin-bottom: $margin-medium;
    background-color: white;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
    width: 100%;
    .chat_message_avatar{
        @extend .agent_profile_avatar;
        align-self: center;
        width: 50px;
        height: 50px;
        background: $primary-color;
        margin: 0px;

        .chat_message_avatar__initials{
            font-size: 1.3rem;
            color: white;
        }

        img{
            background-color: $primary-color;
            padding: 10px;
        }
    }
}

.incoming_meeting_actions{
    background-color: white;
    display: flex;
    justify-content: space-between;
    border: 1px solid $border-color;
    padding: 0.5rem $padding-medium;
    align-items: center;
    margin-bottom: $margin-medium;
    border-radius: $border-radius-medium;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
}

.incoming_meeting_details{
    display: flex;
    align-items: center;
}

.incoming_meeting__username{
    font-size: 1.3rem;
    font-weight: bold;
    text-emphasis: center;
    margin-bottom: $margin-small;
}

.incoming_meeting__actions{
    display: flex;
}

.incoming_meeting__label{
    font-size: 1rem;
    color: lighten($charcoal, 40%);
    margin-bottom: $margin-small;
}

.incoming_meeting__action{
    position: relative;
    display: block;
    padding: 0px;
    height: 35px;
    width: 35px;
    background-color: $online-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 50%;
    color: white;
    margin-right: $margin-small;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 0.2s;
    svg{
        position: relative;
        z-index: 222;
        display: block;
    }
    &:hover{
        background: darken($online-color, 15%);
    }

    &.decline{
        background-color: $warning-red;
        &:hover{
            background: darken($warning-red, 5%);
        }
    }

    &:last-child{
        margin-right: 0px;
    }

    &[disabled]{
        cursor: not-allowed;
        opacity: 0.3;
        background-color: $charcoal;
        &:hover{
            background-color: $charcoal;
        }
    }

    @media  screen and (max-width: $mobile-breakpoint) {
        width: 45px;
        height: 45px;
        font-size: 1.4rem;
    }
}