.chat{
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
    background-size: cover;
    background-position: 50%;

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100vw;
        overflow-y: auto;    
    }
}

.chats_preloader{
    @extend .preloader;
    padding: 1rem 0px;
    width: auto;
    height: auto;
    position: inherit;
    left: unset;
    top: unset;
    background-color: $offwhite;
}