.online_indicator{
    width: 15px;
    height: 15px;
    background-color: $online-color;
    border-radius: 50%;
    flex-shrink: 0;

    &.offline{
        background-color: $warning-red;
    }
}