
.widget{
    position: relative;
    display: block;
    margin-bottom: 1rem;
    flex-shrink: 0;
    border: 1px solid $border-color-light;
    border-radius: $border-radius-medium;
    &:last-child{
        margin-bottom: 0px;
    }
}

.widget_title{
    display: flex;
    align-items: center;
    padding: $padding-medium;
    padding-bottom: 0rem;
    font-weight: 500;
}

.widget_actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.widget_action{
    cursor: pointer;
    color: $grey;
    cursor: pointer;    
    margin-right: 1rem;
    &:last-child{
        margin-right: 0px;
    }

    &:hover{
        color: $primary-color;
    }
}

.widget_title__inner{
    font-size: 0.8rem;
    font-weight: 500;
    color: #777;
    margin-bottom: 0.5rem;
    text-align: center;
}

.widget_inner{
    padding: $padding-medium;
}

.widget_actions__inner{
    display: flex;
    align-items: center;
}

.widget_actions .btn{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-right: $margin-small;

    &:last-child{
        margin-right: 0px;
    }

    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.2rem;
    }
}