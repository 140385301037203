.edubot_page{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.edubot_topbar{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid $border-color;
    height: 80px;
    flex: 0 0 80px;
    .topbar_title{
        font-weight: bold;
        font-size: 20px;
        flex-grow: 1;
    }

    .edubot_topbar__back_button{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        font-size: 20px;
        margin-right: $margin-medium;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 50%;
        &:hover{
            background-color: #f2f2f2;
        }
    }
}

.edubot_topbar_actions{
    display: flex;
    align-items: center;

    .MuiDivider-root{
        margin: 0px 8px;
    }

    button{
        display: flex;
        align-items: center;
        background-color: transparent;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
        padding-left: 16px;
        padding-right: 12px;
        border: none;
        margin: 0px 8px;
        transition: all 0.3s ease;
        color: $grey;
        cursor: pointer;
        border-radius: 10px;
        
        &.delete{
            color: $warning-red;
        }

        svg{
            margin-left: 4px;
            font-size: 18px;
            font-weight: 400;
        }

        &:hover{
            background-color: #f2f2f2;
        }

        &:first-child{
            margin-left: 0;
        }

        &:last-child{
            margin-right: 0px;
        }

        &.primary{
            background-color: $primary-color;
            color: white;
        }

        &:disabled{
            cursor: default;
            opacity: 0.5;

            :hover{
                background-color: initial;
            }
        }
    }
}

.edubot_page_container{
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
    flex: 1 0 auto;
}

.edubot_details_pane{
    position: relative;
    display: block;
    padding: 32px;
    background-color: white;
    border-right: 1px solid $border-color;
    width: 100%;
    max-width: 640px;
    flex-shrink: 0;
    overflow-y: auto;
}

.edubot_details_section__label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    color: $grey;
    margin-bottom: $margin-medium;
}

.edubot_details_section{
    position: relative;
    display: block;
    width: 100%;
    padding: 24px;
    border-radius: $border-radius-small;
    border: 1px solid $border-color;
    margin-bottom: 24px;
    font-size: 14px;
}

.edubot_detail{
    display: grid;
    grid-template-columns: 156px auto;
    column-gap: 56px;
    margin-bottom: 16px;
    grid-auto-flow: row;

    &:last-child{
        margin-bottom: 0px;
    }

    .edubot_detail__label{
        color: $grey;
    }

    .edubot_detail__label__description{
        font-weight: 400;
        font-size: 14px;
        margin-top: 5px;
        color: $grey;
    }

    &.detail_column{
        display: block;

        .edubot_detail__label{
            margin-bottom: 12px;
        }
    }
}

.edubot_detail_message{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .message_icon{
        font-size: 24px;
    }

    .message_title{
        font-weight: 500;
        margin-bottom: $margin-small;
    }

    .btn{
        margin-top: $margin-medium;
    }
}

.edubot_preview_widget{
    display: flex;
    padding: 35px 30px;
    flex-grow: 1;
    flex-direction: column;

    #chattabotz_webchat_widget{
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }

    #web_chat{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-grow: 1;
        z-index: 0 !important;
    }

    .webchat_resize_action{
        display: none !important;
    }

    .web_chat__container{
        position: relative !important;
        right: unset !important;
        bottom: unset !important;
        height: unset !important;
        max-height: unset !important;
        margin-bottom: 0px !important;
        flex-grow: 1 !important;
        flex-basis: 0;
    }

    .web_chat__trigger{
        display: none !important;
    }
}

.edubot_edit_page{
    .edubot_details_pane{
        max-width: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .edubot_detail__label{
        font-size: 16px;
        font-weight: 500;
        color: $charcoal;
    }

    .edubot_details_pane__inner{
        max-width: 1000px;
        width: 100%;
    }

    .edubot_detail{
        grid-template-columns: 40% 1fr;
    }    
}

.module_page{
    .edubot_details_pane{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        
        .col-9{
            width: 66.6% !important;
        }
        
        .col-3{
            width: 33.3% !important;
            margin-left: $margin-medium;
        }
    }
}

.module_list__item{
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid $border-color;
    transition: background-color 0.3s ease;
    cursor: pointer;
    justify-content: space-between;
    border-radius: $border-radius-small;
    background-color: $offwhite;
    margin-bottom: $margin-medium;

    &:last-child{
        margin-bottom: 0px;
    }

    &:active{
        transition: opacity 0.3s ease;
        opacity: 0;
    }

    &.draggable_overlay{
        cursor: grabbing;
    }

    &:hover{
        background-color: #f2f2f2;
    }

    .module_list__item_icon{
        font-size: 30px;
        margin-right: 1rem;
        color: $grey;
    }

    .module_list__item_details{
        flex-grow: 1;

        .edubot_detail__value{
            color: $grey;
        }
    }
}

.edubot_detail__value{
    display: flex;
    
    svg{
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-right: 5px;
    }

    &.column{
        flex-direction: column;
    }
}

.edubot_details_section__divider{
    margin: 24px 0px !important;
}

.section_list__item{
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid $border-color;
    transition: background-color 0.3s ease;
    cursor: pointer;
    justify-content: space-between;
    border-radius: $border-radius-small;
    background-color: $offwhite;
    margin-bottom: $margin-medium;


    &:active{
        transition: opacity 0.3s ease;
        opacity: 0;
    }

    &.draggable_overlay{
        cursor: grabbing;
    }

    &:last-child{
        margin-bottom: 0px;
    }

    &:hover{
        background-color: #f2f2f2;
    }

    .section_list__item_icon{
        font-size: 30px;
        margin-right: 1rem;
        color: $grey;
    }

    .section_list__item_details{
        flex-grow: 1;

        .edubot_detail__value{
            color: $grey;
        }
    }
}

.react-flow__node.selected .step_node{
    outline: 2px solid $primary-color;
}

.step_node{
    background-color: #f2f2f2;
    border-radius: 10px;
    border: 1px solid $border-color;
    width: 280px;
    padding: 12px;
    transition: background-color 0.2s ease;
    cursor: pointer;
    &.hidden{
        opacity: 0;
        padding: 0px;
        background-color: transparent;
    }

    &:hover{
        background-color: #e6e6e6;
    }
}

.step_config_preview{
    background-color: white;
    border-radius: 8px;
    padding: 16px 12px;
}

.step_config_preview__item{
    margin-bottom: $margin-medium;

    &:last-child{
        margin-bottom: 0px;
    }
}

.step_node__header{
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: $margin-medium
}

.step_node__icon{
    flex-shrink: 0;
    font-size: 14px;
    margin-right: 10px;
    color: $charcoal;
    svg{
        display: flex;
        align-items: center;
    }
}

.step_node__info{
    flex-grow: 1;
}

.step_node__info_name{
    font-weight: 600;
    font-size: 12px;
    color: $charcoal;
}

.step_node__info_description{
    font-size: 12px;
    font-style: italic;
    color: $grey;
}

.edubot_edit_page__side_pane{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    background-color: white;
    border-left: 1px solid $border-color;
    flex-shrink: 0;
}

.edubot_edit_page__side_pane__topbar{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color;
    padding: 0px 20px;
    height: 70px;
}

.edubot_edit_page__side_pane__content{
    padding: 20px;

    .edubot_detail{
        border-bottom: 1px solid $border-color;
        padding: 15px 0px;

        &:first-child{
            padding-top: 0px;
        }

        &:last-child{
            border-bottom: none;
            padding-bottom: 0px;
        }
    }
}

.edubot_edit_page__side_pane__title{
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    font-size: 18px;
    line-height: 20px;
}

.information_circle{
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    margin-left: 5px;
    color: $grey;

    svg{
        display: flex;
        align-items: center;
    }
}

.edubot_edit_page__side_pane__actions{
    display: flex;
    button{
        display: flex;
        align-items: center;
        height: 35px;
        width: 35px;
        background-color: transparent;
        cursor: pointer;
        transition: all 0.3s;
        border: none;
        &:hover{
            background-color: #f2f2f2;
        }

        svg{
            font-size: 25px;
        }

        &.delete{
            color: $warning-red;
            
            svg{ font-size: 18px; }
        }
    }
}

.edubot_edit_page__side_pane__text{
    font-size: 14px;
    color: $charcoal;
}

.create_module_modal, .create_step_modal{
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    max-height: 800px;
    height: 95%;
    width: 95% !important;
    justify-content: space-between;

    .modal__content{
        flex-grow: 1;
        flex-basis: 0;
        overflow-y: auto;
    }
}

.module_template_list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 30px;
    margin-top: $margin-large;
    padding: 0px $padding-medium;

    .module_template_list__item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid $border-color;
        border-radius: $border-radius-small;
        padding: 25px;
        cursor: pointer;
        transition: all 0.3s ease;
        border-top: 5px solid $primary-color;
        &:hover{
            background-color: $offwhite;
        }
    }

    .module_template_list__item_icon{
        color: $charcoal;
        margin-bottom: $margin-medium;
        font-size: 40px;
    }

    .module_template_list__item_content{
        flex-grow: 1;
    }

    .module_template_list__item_name{
        font-size: 20px;
        color: $charcoal;
        margin-bottom: $margin-small;
    }

    .module_template_list__item_description{
        font-size: 14px;
        color: $grey;
    }

    .module_template_list__item_actions{
        display: flex;
        align-items: flex-end;
    }

    .module_template_list__item_bottom_bar{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: $margin-large;
    }
}

.connectors_badge{
    display: flex;
    align-items: center;
}

.connectors_badge__icon{
    img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: $border-radius-small;

        &:last-child{
            margin-right: 0px;
        }
    }
}

.step_edge{
    z-index: 20000;
}

.step_edge__add{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: none;
    cursor: pointer;
    box-shadow: rgba(black, 0.11) 0px 0.6px 1.8px, rgba(black, 0.133) 0px 3.2px 7.2px;
    transition: all 0.2s ease;
    padding: 0px;
    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    &:hover{
        background-color: $offwhite;
    }

    &:active{
        background-color: #e6e6e6;
        transform: scale(0.9);
    }
}

.add_step_popup{
    position: absolute;
    background-color: white;
    left: 0;
    top: 40px;
}

.edubot_detail__value__message_add{
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: $margin-medium;

    &:last-child{
        margin-bottom: 45px;
    }
}

.edubot_detail__value__message_delete{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0px;
    border-radius: $border-radius-small;
    margin-left: 8px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    color: $grey;

    &:hover{
        background-color: #f2f2f2;
    }

    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px;
        padding: 0px;
        font-size: 20px;
    }
}

.edubot_detail__value_select__option{
    display: flex;
    cursor: pointer;
    padding: $padding-medium;
    align-items: flex-start;
    transition: all 0.3s ease;
    margin-block: 0px;

    &.flex-middle
    {
        align-items: center;
    }

    &:hover{
        background-color: #f2f2f2;
    }

    .edubot_detail__value_select__option__icon{
        display: flex;
        align-items: center;
        font-size: 22px;
        margin-right: 5px;
        flex-shrink: 0;

        svg{
            margin: 0px;
            padding: 0px;
            color: $grey;
        }
    }
}

.edubot_detail__value_select__option__label{
    font-size: 15px;
    line-height: 15px;
}

.edubot_detail__value_select__option__description{
    font-size: 14px;
    color: $grey;
    margin-top: $margin-small;
}

.edubot_detail__value_img_value{
    max-width: 160px;
    max-height: 70px;
    border-radius: 8px;
    border: 1px solid $border-color;
}

.connector_list_item{
    display: flex;
    border-bottom: 1px solid $border-color;
    padding: 16px 0px;
    align-items: ce;
    &:first-child{
        padding-top: 0px;
    }

    &:last-child{
        border-bottom: none;
        padding-bottom: 0px;
    }

}

.connector_list_item__icon{
    flex-shrink: 0;
    margin-right: $margin-medium;
    img{
        width: 40px;
        height: 40px;
        object-fit: cover;
        object-position: center;
    }
}

.connector_list_item__name{
    font-weight: 600;
    margin-bottom: 5px;
}

.connector_list_item__description{
    font-size: 13px;
    color: $grey;
}