.chat_event{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat_event__content{    
    position: relative;
    text-align: left;
    background: #fff;
    padding: .5rem 1rem;
    width: 85%;
    max-width: 500px;
    margin-top: $margin-medium;
    margin-bottom: $margin-medium;
    border-left: 3px solid rgba($primary-color, 0.55);
    background-color: rgba($primary-color, 0.06);
    border-radius: 0.2rem;
    box-shadow: 7.5px 2.5px 5px rgba(black, 0.08);
    white-space: pre-line;

    &.chat_note__content{
        background-color: rgba(darkorange, 0.06);
        border-color: rgba(darkorange, 0.55);
    }

    .timestamp{
        position: absolute;
        bottom: 0;
        right: .5rem;
        padding: .3rem;
        font-size: .6rem;
    }
}

.chat_event__title{
    font-weight: 600;
    color: $charcoal;
    font-size: 0.8rem;
}

.chat_event_text{
    font-size: .7rem;
    color: #888;
    max-width: 88%;
    white-space: pre-line;
    word-break: break-word;
}