.chat_messages_scroll{
    flex-grow: 1;
    height: 100%;
}

.chat_messages{
    position: relative;
    flex-grow: 1;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: scroll

    div{
        z-index: 1;
    }

    &::before{
        // position: fixed;
        // display: flex;
        // width: 100%;
        // height: 100%;
        // background: rgba(250,250,250,.975);
        // content: "";
        // z-index: 0;
    }
}

.messages_scroll{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.no_message{
    @extend .chat_date_badge;
    max-width: 150px;
}