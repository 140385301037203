.chat_actions{
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.chat_actions__container{
    display: flex;
    align-items: center;
}

.chat_actions_container{
    display: flex;
    align-items: center;
}

.chat_actions_dropdown{
    position: absolute;
    top: 100%;
    margin-top: -0.4rem;
    right: 0;
    background: white;
    box-shadow: 0px 0px 15px rgba(black, 0.2);
    border-radius: $border-radius-small;
    border-top: 5px solid $primary-color;
    width: 90vw;
    max-width: 150px;
}

.chat_actions_dropdown__item{
    display: block;
    text-align: right;
    background: transparent;
    border: none !important;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.6rem $padding-medium;    
    white-space: nowrap;
    cursor: pointer;
    outline: none !important;
    font-family: inherit;
    color: #444;

    &[disabled]{
        opacity: 0.6;
        cursor: default;
    }
}

.mobile_info{
    display: none !important;

    @media screen and (max-width: $mobile-breakpoint) {
        display: flex !important;
    }
}

.chat_action_button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    margin-right: $margin-small;
    cursor: pointer;
    color: $charcoal;
    border-radius: $border-radius-large;
    border: 1px solid $border-color;
    background-color: transparent;
    padding: $padding-small 0.8rem;
    transition: all 0.3s ease;

    &:focus{
        outline: none;
    }

    &:active{
        background-color: darken($offwhite, 6%);
    }

    &:last-child{
        margin-right: 0px;
    }

    &[disabled]{
        opacity: 0.6;
        cursor: default;
    }
}

.chat_action_button__label{
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 0.3rem;

    @media screen and (max-width: $mobile-breakpoint) {
        display: none;
    }
}