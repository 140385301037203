.admin_dashboard{
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.admin_menu{
    display: flex;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid $border-color;
    align-items: center;
    padding: $padding-medium;

    h2{
        font-size: 1.3rem;
        line-height: 1.3rem;
        margin: 0px;
        color: $charcoal;
        font-weight: 500;
    }
}

.admin_menu__logo{
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: $margin-medium;
}

.admin_menu_inner{
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
}

.admin_menu__item{
    text-decoration: none;
    margin-right: $margin-medium;
    background-color: transparent;
    border: none;
    padding: 0px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    color: $primary-color;
    padding: 0.5rem $padding-medium;
    border: 1px solid $primary-color;
    border-radius: 30rem;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s;

    &:last-child{
        margin-right: 0px;
    }
}

.admin_settings_container{
    flex-grow: 1;
    background-color: #f7f7f7;
    overflow-y: auto;
}

.admin_settings_widget{
    border: 1px solid $border-color;
    border-radius: $border-radius-small;
    padding: $padding-medium;
    margin-bottom: $margin-medium;

    &:last-child{
        margin-bottom: 0px;
    }

    h3{
        margin: 0px;
        margin-bottom: $margin-small;
    }

    p{
        margin: 0px;
        margin-bottom: $margin-medium;
        color: #777;
    }
}

.company_list_item{
    display: flex;
    align-items: center;
    padding: $padding-medium 0px;
    border-bottom: 1px solid $border-color;
    font-weight: 500;
    &:last-child{
        border-bottom: 0px;
        padding-bottom: 0px;
    }

    img{
        width: 50px;
        object-fit: cover;
        height: 50px;
        margin-right: $margin-medium;
        border-radius: $border-radius-small;
        border: 1px solid $border-color;
    }
}

.company_list_item__name{
    flex-grow: 1;
}

.company_list_item__actions{
    display: flex;
    align-items: center;
    margin-left: $margin-medium;
}

.company_list_item__action{
    margin-right: $margin-small;
    cursor: pointer;

    svg{
        display: flex;
        align-items: center;
        font-size: 1rem;
        line-height: 1rem;
    }

    &:last-child{
        margin-right: 0px;
    }
}

.company_list_item__badge{
    display: flex;
    align-items: center;
    background-color: rgba($primary-color, 0.2);
    margin-right: $margin-small;
    font-size: 0.75rem;
    border-radius: $border-radius-large;
    padding: $padding-small 0.5rem;

    img{
        height: 20px;
        width: 20px;
        margin-right: 0.2rem; 
        border-radius: 50%;
    }
}