.agent_history{
    position: relative;
    display: block;
    max-height: 400px;
    overflow-y: auto;
}

.agent_history_item{
    position: relative;
    border-bottom: 1px solid $border-color-light;
    padding: 0.75rem 0px;
    padding-right: 0.5rem;

    .agent_profile_avatar{
        margin-bottom: 0px;

        ~ .popup_box{
            margin-bottom: 0;
        }
    }

    &:last-child{
        border-bottom: none;
        padding-bottom: 0;
    }
}

.agent_history_item_inner{
    display: flex;
    align-items: center;
}

.agent_history_item__details{
    flex-grow: 1;
}

.agent_history_item__expand{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.agent_history_list{
    margin-bottom: 1.2rem;
    &:last-child{
        margin-bottom: 0;
    }
}

.agent_history_item__details__name{
    font-weight: 500;
}

.agent_history_item__connected{
    font-weight: 700;
    color: $online-color;
}

.agent_history_item_dropdown{
    margin-top: 0.5rem;
    border-radius: $border-radius-small;

    .agent_history_item_inner{
        padding: $padding-small 0px;
    }

    > div{
        margin-bottom: 0.3rem;

        &:last-child{
            margin-bottom: 0;
        }
    }
}