.help_request_widget_item{
    padding: $padding-medium 0px;
    border-bottom: 1px solid $border-color-light;

    &:first-child{
        padding-top: 0px;
    }

    &:last-child{
        border-bottom: 0px;
        padding-bottom: 0px;
    }
}

.help_request_widget_item__top_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .timestamp{
        line-height: 0.7rem;
    }
}

.help_request_widget_item__body{
    font-size: 0.8rem;
    margin: $margin-small 0px;
    color: #888;
    margin-bottom: $margin-medium;
    white-space: pre-line;
    word-break: keep-all;
}

.help_request_widget_item__actions{
    display: flex;

    .btn{
        text-decoration: none;
        margin-right: 0.3rem;
        &:last-child{
            margin-right: 0px;
        }
    }
    
}