.chat_media_preview{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $offwhite;
    height: 90vh;
    padding: $padding-medium 0px;
    max-height: 400px;
    border-top: 1px solid $border-color-light;
    box-shadow: 0px -5px 15px rgba(black, 0.2);
    animation: fadeIn 0.3s ease-in-out;
}

.chat_media_preview__container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    max-width: 500px;
    padding: $padding-medium;
    border: 5px dashed $light-grey;
    margin: 1rem 0px;

    img{
        @extend media_preview_base;
    }

    video{
        @extend media_preview_base;
        border-radius: $border-radius-small;
    }

    .chat_media_preview__container__default{
        @extend media_preview_base;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: $border-radius-large;
        box-shadow: 0px 5px 15px rgba(black, 0.1);
        cursor: default;
        svg{
            font-size: 3rem;
            margin-bottom: $margin-small;
            color: $primary-color;
        }
    }
}

.chat_media_preview__container__default__type{
    font-size: 1.3rem;
    color: $grey;
    text-align: center;
}

media_preview_base{
    object-fit: contain;
    max-width: 100%;
    height: 80%;
    margin-bottom: $margin-small;
}

.chat_media_preview__file_info{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $charcoal;
    flex-grow: 1;
    flex-shrink: 0;
    border-radius: $border-radius-small;
    padding: $padding-medium;
}

.chat_media_preview__file_info__title{
    font-weight: 500;
    font-size: 0.8rem;
    color: white;
}

.chat_media_preview__file_info__type{
    color: white;
    font-size: 0.7rem;
}

.chat_media_preview__file_remove{
    position: absolute;
    top: 0%;
    right: 0;
    padding: $padding-small;
    color: white;
    cursor: pointer;
}