.message{
    display: flex;
    flex-direction: row;

    .message_inner{
        position: relative;
        display: flex;
        flex-direction: row;
        margin: $margin-medium;
        animation: bubbleAppear 0.9s ease;
        
        &.no_animation{
            animation: none !important;
        }
    }

    .message__text{
        text-align: left;
        padding: 13px;
        white-space: pre-line;
        word-break: break-word;
    }
}

.message_inner__content{
    display: flex;
    flex-direction: column;
    align-content: center;
}

.message__buble{
    display: inline-block;
    width: fit-content;
    height: fit-content;
    max-width: 400px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0,0,0,.08);
    font-size: .8rem;
    color: #000;
    margin-bottom: .2rem;
    color: #2a2a2a;
    cursor: default;
    overflow: hidden;
    word-break: break-all;

    &.internal{
        background-color: lighten($secondary-color, 40%);
    }
    
    @media screen and (max-width: $mobile-breakpoint) {
        max-width: 75vw;
    }
}

@keyframes bubbleAppear{
    from{
        opacity: 0;
        transform: scale(0.95);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}

.message__timestamp{
    font-size: .8rem;
    color: #8a8a8a;
}

.message.user{
    justify-content: flex-end;

    .message_inner{
        text-align: right;
        flex-direction: row-reverse;
    }

    .message_inner__content{
        align-items: flex-end;
    }
}


.message__flow_label{
    padding: 0.3rem 0px;
    color: $grey;
    font-weight: 500;

}

.message_outer_bottom{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.view_logs_button{
    @extend a;
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.3rem;
    right: -30px;
    width: 30px;
    top: 5px;
    animation: fadeIn 0.5s ease;
    svg{
        display: flex;
        font-size: 1.2rem;
        align-items: center;
        margin: 0px;
        margin-right: 0.1rem;
    }
}

.message__flow_button{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 25px;
    background: linear-gradient($primary-color, lighten($primary-color, 10%));
    color: white;
    padding: 0px 1.5rem;
    font-size: 0.7rem;
    font-weight: 500;
    cursor: pointer;

    svg{
        font-size: 1rem;
        margin-right: 0.3rem;
    }

    &:hover{
        background: linear-gradient(lighten($primary-color, 5%), lighten($primary-color, 15%));
    }
}