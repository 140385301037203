.chats_app{
    .meeting_room{
        position: fixed;
        left: 0%;
        top: 0;
        z-index: 22222;

        &.maximized{
            transform: none !important;
        }
    }
}