// Colors
$user-color: radial-gradient(circle, rgb(83, 83, 83) 0%, rgba(54,54,54,1) 100%);
$bot-color: white;

.chat_message_avatar{
    position: relative;
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin: 0 8px;
    margin-bottom: 1.4rem;
    border-radius: 50%;
    overflow: hidden;
    cursor: default;

    &.bot{
        background: $bot-color;
        img{
            max-width: 70%;
            max-height: 70%;
        }
    }

    &.user{
        background: $user-color;
        img{
            max-width: 100%;
            max-height: 100%;
            border-radius: 50%;
            padding: 2px;
        }
    }
}

.chat_message_avatar__initials{
    position: relative;
    width: 100%;
    height: fit-content;
    text-align: center;
    color: #fff;
    fill: #fff;
    font-weight: 600;
    font-size: .8rem;
    line-height: 0.8rem;
    letter-spacing: 1px;
}