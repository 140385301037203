.queue_list_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding-medium 0px;
    border-bottom: 1px solid $border-color;

    &:last-child{
        border-bottom: 0px;
    }
}

.queue_list_item__name{
    font-weight: 500;
}

.queue_list_item__subtitle{
    font-size: 0.8rem;
    color: #777;
}

.queue_list__action{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.1rem;
    color: #5a5a5a;
    cursor: pointer;
    line-height: 1rem;
    margin-right: 1rem;

    &:last-child{
        margin-right: 0px;
    }
}

.queue_list__actions{
    display: flex;
    align-items: center;
}