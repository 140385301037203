.draggable_step{
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid $border-color;
    cursor: grab;
    justify-content: space-between;
    border-radius: $border-radius-small;
    background-color: $offwhite;
    margin-bottom: $margin-medium;
    transition: scale 0.2 ease;

    &:last-child{
        margin-bottom: 0px;
    }

    &:hover{
        background-color: #f2f2f2;
    }

    &:active{
        opacity: 0;
    }

    &.draggable_overlay{
        //animation: scaleIn 0.3s ease forwards;
    }

    @keyframes scaleIn {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.1);
        }
    }

    .draggable_step__reorder_icon{
        font-size: 20px;
        margin-right: 1rem;
        color: $grey;
    }

    .draggable_step__icon{
        font-size: 25px;
        margin-right: 1rem;
        color: $grey;
    }

    .draggable_step__info{
        flex-grow: 1;

        .draggable_step__info_name{
            color: black;
            font-size: 0.8rem;
            font-weight: 600;
        }

        .draggable_step__info_description{
            color: $grey;
            font-size: 0.8rem;
        }
    }
}