.job_log_container{
    border: 1px solid $border-color;
    border-radius: $border-radius-medium;
    box-shadow: inset 0px 0px 5px rgba(black, 0.05);
    table{
        background-color: rgba($primary-color, 0.03);
        tr:last-child{
            td{
                border-bottom: 0px;
            }
        }
    }

    max-height: 300px;
    overflow-y: auto;
}

.job_log_column{
    padding-left: 0px !important;
    padding-right: 0px !important;

    .status_badge{
        &.error{
            @extend .rejected;
        }

        &.success{
            @extend .complete;
        }
    }
}

.job_log_no_logs{
    padding: $padding-medium;
    text-align: center;
    color: #888;
}