.whatsapp_template_list{
    position: relative;
    width: 100%;
    table-layout: fixed;
    margin-top: $margin-medium;
    tbody, tr{
        width: 100%;
    }
    
    tr.selectable{
        cursor: pointer;
        &:hover{
            background-color: darken(white, 3%);
            border-color: darken(white, 3%);
        }
    }

    col.language{
        width: 40%;
    }

    col.whatsapp_template_list_actions{
        width: 12%;
    }
}

.whatsapp_template_composer__url_preview{
    font-weight: 600;
    font-style: italic;
    font-size: 0.9rem;
    color: $charcoal;
    margin-bottom: $margin-medium;
}

.recipient_badge{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;   
    background-color: rgba($primary-color, 0.75);
    width: fit-content;
    max-width: 200px;

    svg{
        margin-right: 0.2rem;
        font-size: 0.8rem;
    }
}

.whatsapp_template_page_actions_holder{
    display: flex;
    justify-content: space-between;
}

.whatsapp_template_page_actions{
    display: flex;

    .btn{
        margin-right: 0.5rem;

        &:last-child{
            margin-right: 0px;
        }
    }
}


.whatsapp_template_list_name{
    width: 30%;
}

.whatsapp_template_list_status{
    width: 20%;
}

.whatsapp_template_list_item__details__name{
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0.3rem;
}

.whatsapp_template_list_item__details__category{
    font-size: 0.7rem;
    color: #888;
}

.whatsapp_template_list_item__language__language_code{
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
}

.whatsapp_template_list_item__language__language_body_preview{
    font-size: 0.7rem;
    color: #888;
}

.whatsapp_template_list_item__status_badge{
    font-size: 0.7rem;
    font-weight: bold;
    background-color: rgba($grey, 50%);
    width: fit-content;
    padding: 0.3rem 0.5rem;
    border-radius: $border-radius-large;
    color: white;

    &.approved{
        background-color: $online-color;
    }
}

.whatsapp_template_builder_option_list_option{
    display: flex;
    cursor: pointer;
    padding: $padding-medium 0.5rem;
    transition: all 0.1s ease;
    border-radius: $border-radius-medium;
    margin: 0px -0.5rem;
    margin-bottom: $margin-small;
    align-items: center;
    &:hover{
        background-color: rgba($charcoal, 0.1);
    }

    &.checked{
        background-color: rgba($primary-color, 0.1);

        &:hover{
            background-color: rgba($primary-color, 0.2);
        }
        .form_checkbox::before{
            position: absolute;
            content: '';
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            background-color: $primary-color;
            border-radius: 50%;
            border: 5px solid white;
        }
    }
}

.whatsapp_template_step__actions{
    padding-top: $padding-medium;
    display: flex;

    .btn{
        margin-right: $margin-small;

        &:last-child{
            margin-right: 0px;
        }
    }
}

.whatsapp_template_builder_option_list_option_icon{
    font-size: 1.8rem;
    margin-right: $margin-medium;
    display: flex;
    align-items: center;
}

.whatsapp_template_builder_option_list_option_label__label{
    font-weight: 600;
}

.whatsapp_template_builder_option_list_option_label__description{
    font-size: 0.8rem;
    color: #888;
}

.form_checkbox{
    position: relative;
    width: 25px;
    height: 25px;
    border: 1px solid $border-color;
    border-radius: 50%;
    margin-right: $margin-medium;
    transition: all 0.1s ease;
    background-color: white;
    flex-shrink: 0;

    &:hover{
        box-shadow: inset 0px 0px 3px rgba(black, 0.1);
    }
}

.whatsapp_template_composer{
    display: flex;
    border: 1px solid $border-color;
    border-radius: $border-radius-medium;
}

.whatsapp_template_composer_items{
    padding: $padding-large;
    flex-grow: 1;

    .form-group{
        margin-bottom: 1.5rem;
        &:last-child{
            margin-bottom: 0px;
            input{
                margin-bottom: 0px;
            }
        }
    }

    .emoji_picker__triger{
        margin: 0px;
    }

    .quill {
        border-radius: 0.3rem;
        width: 100%;
        word-break: break-word;
        .ql-toolbar{
            border-top-right-radius: $border-radius-small;
            border-top-left-radius: $border-radius-small;
        }

        .ql-container{
            border-bottom-right-radius: $border-radius-small;
            border-bottom-left-radius: $border-radius-small;
        }
    }
}

.path_item_editor_text_editor__actions{
    display: flex;
    justify-content: space-between;
    margin-top: $margin-small;

    .max-length{
        display: flex;
        align-items: center;
        right: 0;
        color: rgba(grey, 0.75);
        padding: 0px 0.6rem;
        font-size: 0.8rem;
        cursor: default;
    }
}

.whatsapp_template_composer_preview{
    position: relative;
    width: 40%;
    flex-shrink: 0;
    background-color: #e5ddd5;
    border-radius: $border-radius-medium;
    overflow: hidden;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    .whatsapp_template_composer_preview_background{
        width: 100%;
        height: 100%;    
        background-size: 366.5px 666px;
        content: '';
        left: 0;
        opacity: .06;
        position: absolute;
        top: 0;
    }
}

.none_found_message{
    text-align: center;
    color: #888;
    font-style: italic;
}

.whatsapp_tempate_preview_bubble_holder{
    position: relative;
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 80%;
    padding: 16px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
}

.whatsapp_tempate_preview_bubble{
    border-radius: 7.5px;
    box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
    background-color: #fff;
    color: #111b21;
    border: none;
    min-height: 33px;
    min-width: 100px;
    position: relative;
    flex: none;
    font-size: 14.2px;
    line-height: 19px;
    padding: 6px 7px 8px 9px;
    z-index: 1;
    overflow: visible;
    width: 100%;
    border-top-left-radius: 0px;
}

.whatsapp_tempate_preview_content{
    font-size: .8rem;
    word-break: break-word;
    white-space: pre-line;

    p{
        padding: 0px;
        margin: 0px;
        padding-right: 30px;
        
    }
    span{
        padding: 0px !important;
        margin: 0px !important;
        margin-block-end: 0px !important;
        margin-block-start: 0px !important;
        color: #282828;
        font-size: 13.6px;
        white-space: pre-wrap;
    }
}

.whatsapp_chevron_bot {
    position: absolute;
    top: 0;
    z-index: 100;
    display: block;
    width: 8px;
    height: 13px;
    color: #fff;
    left: -8px;
}

.whatsapp_header{    
    color: rgba(0, 0, 0, .76);
    font-size: 15px;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 9px;
}

.whatsapp_footer{
    color: rgba(0, 0, 0, .45);
    font-size: 13px;
    line-height: 17px;
    margin-top: 7px;
    margin-bottom: 9px;
}

.whatsapp_timestamp{
    bottom: 3px;
    color: rgba(0, 0, 0, .4);
    font-size: 11px;
    height: 15px;
    line-height: 15px;
    position: absolute;
    right: 7px;
}

.button_editor_item{
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin-medium;

    &:last-child{
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: 0px;
    }

    .button_editor_item_field{
        flex-grow: 1;
        margin-right: $margin-small;
        height: fit-content;

        .form_select, input{
            margin-bottom: 0px;
        }

        .form_select{
            min-width: 140px;
        }

        &:last-child{
            margin-right: 0px;
        }
    }
}


.whatsapp_preview_call_to_action_button{
    height: 34px;
    font-size: 14px;
    line-height: 14px;
    color: #009de2;
    padding: 3px 9px 3px 9px;
    text-align: center;
    min-width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img{
        width: 15px;
        height: 15px;
        margin-right: 0.2rem;
    }

    &:first-child{
        border-top: 1px solid $border-color;
        margin-top: $margin-small;
    }

    &:last-child{
        padding-bottom: 0px;
    }
}

.whatsapp_template_composer_preview__title{
    padding: $padding-medium;
    padding-bottom: 0px;
    font-weight: 600;
    font-size: 0.9rem;    
    color: $charcoal;
}

.whatsapp_preview_quick_reply_buttons{
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    z-index: 1;
}

.whatsapp_preview_quick_reply_button{
    background-color: #fff;
    overflow: hidden;
    border-radius: 7.5px;
    box-shadow: 0 1px 0.5px 0 rgba(11, 20, 26, 0.13);
    margin: 3px 1.5px 0;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 34px;
    font-size: 14px;
    line-height: 20px;
    color: #009de2;
    border: none;
    text-align: center;
    padding: 6px 9px 6px 9px;
}

.whatsapp_tempate_preview_content_media{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100px;
    color: white;
    background-color: #ccd0d5;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80px 80px;
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 240px;
    height: 120px;
    margin-bottom: $margin-small;
}

.whatsapp_template_composer__label{
    display: flex;
    align-content: center;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0.3rem;

    .whatsapp_template_composer__optional_badge{
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 0.3rem 0.3rem;
        height: fit-content;
        font-size: 0.6rem;
        line-height: 0.6rem;
        background-color: rgba($primary-color, 0.1);
        border-radius: $border-radius-large;
        margin-left: 0.3rem;
    }
}

.whatsapp_template_composer__label_description{
    margin-bottom: $margin-small;
    color: #888;
    font-size: 0.8rem;
}

