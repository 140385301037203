.events_widget{
    max-height: 200px;
    overflow-y: auto;
}

.events_widget_item{
    padding: $padding-small 0px;
    border-bottom: 1px solid $border-color-light;

    &:first-child{
        padding-top: 0px;
    }

    &:last-child{
        border-bottom: 0px;
        padding-bottom: 0px;
    }
}

.event_widget_title{
    font-size: 0.8rem;
    font-weight: 500;
}

.event_widget_body{
    font-size: 0.8rem;
    color: $charcoal;
}