.chat_status_icon{
    position: absolute;
    width: 40%;
    height: 40%;
    background-color: $charcoal;
    bottom: -10%;
    right: -10%;
    border-radius: 50%;
    border: 2px solid rgba(white, 0.9);
    box-shadow: 0px 5px 15px rgba(black, 0.5);

    &.open{
        background-color: $online-color;
    }

    &.closed{
        background-color: $warning-red;
    }

    &.help_requested{
        background-color: orange;
    }
}