.agent_profile_card{
    display: flex;
    flex-direction: column;
    align-items: center;

    .agent_profile_avatar{
        align-self: unset;
    }
}

.agent_info{
    position: relative;
    display: block;
}

.agent_name{
    font-size: 2rem;
    font-weight: bold;
    margin-top: $margin-large;
    margin-bottom: 0.5rem;
}