.chat_list_actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding-small $padding-medium;
    background-color: white;
    border-bottom: 1px solid $border-color-light;

    .badge{
        padding: 4px 6px;
    }
}

.chat_list_actions__inner{
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
}

.chat_list_actions__container{
    display: flex;
    align-items: center;
}

.chat_list__action{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $charcoal;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 300;
    color: #777;
    border-right: 1px solid $border-color;
    margin: 0.1rem 0px;
    padding-right: $margin-small;
    margin-right: $margin-small;
    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        line-height: 0.8rem;
        margin-right: 0.2rem;
        color: $charcoal;
        display: none;
    }

    &:hover{
        color: $primary-color;
    }

    &:last-child{
        margin-right: 0px;
        border-right: none;
        padding-right: 0px;
    }

    &.selected{
        color: $primary-color;
    }
}

.chat_list__action_btn{
    font-size: 0.7rem;
    padding: $padding-small $padding-medium;
    border: 1px solid $border-color;
    margin-right: 0.3rem;
    border-radius: $border-radius-large;
    cursor: pointer;

    &.active{
        background-color: $primary-color;
        color: white;
        border-color: $primary-color;
    }

    &:last-child{
        margin-right: 0px;
    }
}

.chat_list_actions_text{
    font-size: 0.8rem;
    padding: $padding-small 0px;
}