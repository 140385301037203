.unanswered_question{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: $padding-medium 0px;
    border-bottom: 1px solid $border-color;

    &:first-child{
        border-top: 1px solid $border-color;
    }
}

.unanswered_question__question{
    margin-bottom: $margin-medium;
}

.unanswered_questions_top_bar{
    margin-bottom: $margin-medium;
    input{
        margin-bottom: 0px;
    }

    .form-group{
        margin-bottom: 0px;
    }
}

.unanswered_questions_actions{
    display: flex;
    align-items: center;
    margin-bottom: $margin-medium;
    .btn{
        margin-right: $margin-small;

        &.selected{
            background-color: $primary-color;
            color: white;
        }

        &:last-child{
            margin-right: 0px;
        }

        span{
            margin-left: 0.2rem;
        }
    }
}

.pending_question{
    @extend .unanswered_question;
    display: flex;
    justify-content: space-between;

    &.disabled::after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(white, 0.5);
    }    
}

.pending_question__actions{
    display: flex;
    align-items: center;
}

.pending_question__action{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 500;
    padding: $padding-medium;
    background-color: $online-color;
    color: white;
    border-radius: $border-radius-small;
    margin-right: $margin-small;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover{
        background-color: darken($online-color, 15%);
    }

    svg{
        margin-right: $margin-small;
    }

    &.reject{
        background-color: $warning-red;

        &:hover{
            background-color: darken($warning-red, 15%);
        }
    }

    &.icon_only{
        svg{
            margin-right: 0px;
        }
    }
}

.pending_question__source{
    font-size: 0.8rem;
    margin: $margin-small 0px;
}

.unanswered_question__form{
    textarea{
        width: 100%;
        max-width: 700px;
        display: block;
        margin-bottom: $margin-medium;
    }
}

.pending_question__details{
    flex-grow: 1;
    margin-right: $margin-medium;
}

.unanswered_question__form__actions{
    display: flex;
    .btn{
        margin-right: $margin-small;
        &:last-child{
            margin-right: 0px;
        }
    }
}