.chats_list{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 330px;
    width: 100%;
    height: 100%;
    background: #fff;
    border-right: 1px solid $border-color-light;

    .list{
        &.collapsed{
            display: none;
            overflow: hidden;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        position: absolute;
        max-width: 100vw;
        width: 100vw;
        &.mobile_expanded{
            display: none;
        }
    }
}

.chat_list__inner_list{
    position: relative;
    display: block;
    overflow-y: auto;
}

.internal_chat_top_bar{
    padding: 0.5rem !important;
}

.chat_list__subtitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    background-color: $offwhite;
    font-size: 0.75rem;
    line-height: 0.8rem;
    font-weight: 500;
    color: $grey;
    padding: 0.75rem $padding-medium;
    margin: 0.6rem;
    border-radius: $border-radius-medium;
    cursor: pointer;
    transition: all 0.2s;

    &:hover{
        background-color: darken($offwhite, 3%);
    }

    svg{
        font-size: 0.8rem;
        line-height: 0.8rem;
        margin-right: $margin-small;
        &.rotated{
            transform: rotate(-90deg);
        }
    }
}

.chat_list__item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    padding: .8rem 0.8rem;
    background: #fff;
    transition: all .3s;
    font-size: 14px;
    cursor: pointer;
    line-height: 1.5;
    margin: 2px 0.6rem;
    border-radius: 1rem;
    
    &:hover{
        background-color: lighten($primary-color, 42%);
    }

    &.active{
        background-color: lighten($primary-color, 38%);
    }

    &:active{
        background-color: lighten($primary-color, 36%);
    }

    .notification_badge{
        margin-right: 0px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        &:hover, &.active{
            background-color: transparent;
        }
    }
}

.chat_list__item_details{
    position: relative;
    display: block;
    flex-grow: 1;
    width: 75%;
    margin-left: 5%;
}

.chat_list__item__title{
    position: relative;
    display: block;
    font-size: .8rem;
    font-weight: 500;
    color: #3a3a3a;
}

.chat_list__item__last_activity{
    position: relative;
    display: flex;
    align-items: center;
    color: #666;
    font-size: .8rem;
    strong{
        font-weight: 500;
    }
    svg{
        margin-right: 0.2rem;
        color: #888;
        font-size: 0.8rem;
    }

    .delivered_check{
        font-size: 1rem;
        line-height: 1rem;
    }

    .chat_list__item__last_activity__sender{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.2rem;

        svg{
            font-size: 1.2rem;
            line-height: 1.2rem;
            margin-right: -0.1rem;
        }
    }
}

.chat_list__item__timestamp{
    @extend .timestamp;
}