.question_log_item{
    display: grid;
    grid-template-columns: 156px auto;
    column-gap: 56px;
    padding-right: 24px;
}

.question_log_item__label{
    font-size: 14px;
    color: #858585;
}

.question_log_modal{
    width: 60%;
    max-width: 1200px;
}

.question_log_item__value{
    font-size: 14px;
}

.question_log_modal{
    max-height: 90vh;
    overflow-y: auto;

    .chunk_list_item{
        padding: 0px;
    }
}

.chunks_carousel__title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chunks_carousel__items{
    overflow-y: auto;
}

.question_log_page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1 0 auto;

    .chunks_carousel__title{
        margin-top: $margin-large;
        strong{
            font-size: 16px;
            color: $charcoal;
        }
    }
}

.question_log_top_bar{
    background-color: white;
    padding: $padding-large;
    border-bottom: 1px solid $border-color;
    height: 145px;
    .form-group{
        margin-bottom: 0px !important;

        .MuiBox-root {
            margin-bottom: 0px;
        }
    }
}

.question_log_details_pane__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    border-bottom: 1px solid $border-color;
    padding-bottom: 30px;
    align-items: flex-end;

    .question_log_list__item__text__username{
        font-size: 16px;
    }

}

.question_log_page_content{
    position: relative;
    display: flex;
    flex-shrink: 0;
    height: calc(100% - 146px);
}

.question_log_list{
    position: relative;
    width: 30%;
    height: 100%;
    max-width: 500px;
    background-color: white;
    border-right: 1px solid $border-color;
    overflow-y: auto;
    flex-shrink: 0;
}

.question_log_list__item{
    display: flex;
    flex-direction: column;
    padding: 25px $padding-large;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
    justify-content: space-between;
    font-size: 14px;
    transition: all 0.2s;

    .question_log_list__item__content{
        display: flex;
    }

    .question_log_list__item__text{
        flex-grow: 1;
        margin-right: $margin-medium;
    }

    &:hover{
        background-color: #f2f2f2;
    }

    &.active{
        background-color: rgba($primary-color, 0.1);
    }

    &:active{
        background-color: rgba($primary-color, 0.2);
    }

    .timestamp{
        flex-shrink: 0;
    }
}

.question_log_model_badge{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($primary-color, 0.2);
    color: darken($primary-color, 10%);
    font-weight: 500;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 4px;
    margin-right: 5px;
    height: fit-content;
}

.question_log_table{
    tr{
        cursor: pointer;

        &:hover{
            background-color: #f2f2f2;
        }
    }
}

.question_log_list__item__details{
    display: flex;
    align-items: center;
    height: 20px;
    padding: 4px 0px;
    margin-top: 20px;
    justify-content: space-between;
}

.question_log_status{
    display: flex;
    align-items: center;
    
    &.upvoted{
        color: green;
    }

    &.downvoted{
        color: red;
    }

    .question_log_status_icon{
        display: flex;
        align-items: center;
    }

    .question_log_status_text{
        font-weight: 500;
    }

    svg{
        font-size: 18px;
        margin-right: 5px;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

.question_log_list__item__revised_status{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    border: 1px solid green;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 15px;
    font-weight: 500;
    color: green;
    background-color: rgba(green, 0.1);
}

.question_log_details_pane{
    position: relative;
    width: 100%;
    flex-grow: 1;
    background-color: white;
    padding: 50px;
    overflow-y: auto;
    box-sizing: content-box !important;

    .chunk_list_item{
        width: 100%;
        border-radius: $border-radius-large;
        border-bottom: 0px;
    }
}

.question_log_details_pane__messages{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.question_log_details_pane__message{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 70%;
    width: fit-content;
    background-color: #f2f2f2;
    padding: 15px 20px;
    border-radius: 17.5px;
    font-size: 14px;
    box-shadow: 5px 5px 15px rgba(black, 0.1);
    white-space: pre-line;
    word-break: break-word;
    
    textarea{
        width: 800px;
        max-width: 100%;
    }
}

.question_log_details_pane__message_edit_label{
    position: absolute;
    top: -30px;
    right: 15px;
    line-height: 30px;
    font-style: italic;
}

.question_log_details_pane__messages__question{
    background-color: $primary-color;
    color: white;
}

.question_log_details_pane__messages__answer{
    align-self: flex-start;
    flex-shrink: 0;
    transition: all 0.2s;
    cursor: pointer;
    border: 1px solid transparent;

    &.revising, &:hover{
        border: 1px solid $primary-color;
        box-shadow: 0px 0px 15px rgba($primary-color, 0.3);
        background-color: darken(#f2f2f2, 2%);
    }

    &.revised{
        border: 1px solid green;
        &:hover{
            box-shadow: 0px 0px 15px rgba(green, 0.3);
            background-color: darken(#f2f2f2, 2%);
        }
    }
}

.question_log_details_pane__messages__answer__label{
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: $charcoal;
    margin-top: -26px;
    font-size: 13px;
    line-height: 22px;
    background-color: green;
    width: fit-content;
    color: white;
    padding: 0px 10px;
    border-radius: 20px;

    svg{
        display: flex;
        align-items: center;
        margin-right: 2px;
        font-size: 15px;
    }
}

.question_log_comment_editor{
    max-width: 400px;
    flex-grow: 1;
    padding-right: 7.5px;
    textarea{
        margin-bottom: $margin-small;
    }

    button{
        margin-right: 5px;

        &:last-child{
            margin-right: 0px;
        }
    }
}

.question_log_comment_editor__charcount{
    font-size: 12px;
    text-align: right;
}

.question_log_comment_editor__title{
    font-size: 12px;
    font-weight: 500;
    color: $grey;
    margin-bottom: $margin-small;
}

.question_log_details_pane__messages__comments{
    display: flex;
    flex-direction: column;
    max-width: 400px;
    flex-grow: 1;
    margin-right: 7.5px;
    
    .question_log_details_pane__messages__comment_add_button{
        align-self: flex-end;
    }
}

.question_log_details_pane__messages__comments__container{
    max-height: 500px;
    overflow-y: auto;
    padding-bottom: 15px;
    padding-right: 7.5px;
}

.question_log_details_pane__messages__comment{
    box-shadow: 5px 5px 15px rgba(black, 0.1);
    height: fit-content;
    width: fit-content;
    border-radius: 8px;
    padding: 15px;
    font-size: 12px;
    color: $grey; 
    font-style: italic;
    border: 1px solid $border-color;
    white-space: pre-line;
    word-break: break-word;
    margin-bottom: 15px;
    align-self: flex-end;
    width: 100%;

    &:last-child{
        margin-bottom: 0px;
    }
}

.question_log_details_pane__timestamp{
    text-align: center;
    color: $grey;
    font-size: 14px;
}

.question_log_details_pane__revisions{
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .question_log_details_pane__revisions__label{
        color: $charcoal;
        font-weight: 500;
        font-size: 14px;
    }

    .btn{
        margin-top: 15px;
    }
}

.view_chat{
    display: inline;
    cursor: pointer;
    text-decoration: underline;

    &:hover{
        color: $primary-color;
    }
}

.question_log_details_pane__revisions__old_answer{
    border: 1px solid $grey;
    padding: 20px 15px;
    width: 100%;
    max-width: 800px;
    border-radius: $border-radius-medium;
    font-size: 14px;
    line-height: 14px;
    white-space: pre-line;
    word-break: break-word;
}

.question_log_details_pane__revisions__old_answer_label{
    display: flex;
    align-items: center;
    margin-top: -28px;
    margin-bottom: 14px;
    background-color: white;
    width: fit-content;
    padding: 0px 10px;
    font-weight: 500;
    color: $grey;

    svg{
        margin-right: 3px;
        font-size: 16px;
    }
}

.question_log_details_pane__revisions__old_answer__content{
    display: flex;
    justify-content: space-between;
}

.question_log_details_pane__revisions__old_answer_edit{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 10px;
    border-radius: 50%;
    border: none;
    width: 40px;
    height: 40px;
    font-size: 18px;
    background-color: transparent;
    transition: all 0.3s;
    cursor: pointer;

    &:hover{
        background-color: #ebe8e8;
    }

    &:active{
        background-color: #dddddd;
    }
}

.question_log_status_buttons{
    display: flex;
}

.question_log_status_button{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color;
    border-radius: $border-radius-medium;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 14px;
    margin-right: 10px;
    transition: all 0.2s;
    overflow: hidden;

    span{
        opacity: 0;
        width: 0;
        transition: all 0.2s;
        font-weight: 600;
    }

    svg{
        flex-shrink: 0;
        font-size: 16px;
    }

    &.active{
        background-color: $primary-color;
        color: white;
    }

    &.selected{
        span{
            width: 80px;
            margin-left: 5px;
            opacity: 1;
        }

        color: white !important;
        
        &.upvote{
            background-color: rgb(52, 192, 52);
            border-color: rgb(52, 192, 52);
        }
    
        &.downvote{
            background-color: rgb(248, 38, 38);
            border-color: rgb(248, 38, 38);
        }
    }
    
    &:hover{
        span{
            width: 80px;
            margin-left: 5px;
            opacity: 1;
        }

        &.upvote{
            color: green;
            border-color: green;
        }
    
        &.downvote{
            color: red;
            border-color: red;
        }
    }

    &:active{
        background-color: #ebe8e8;
    }

    &:last-child{
        margin-right: 0px;
    }

    &:disabled{
        cursor: default;
        pointer-events: none;
    }
}

.question_log_list__item__text__username{
    font-weight: 500;
    font-size: 14px;
    color: $charcoal;
    margin-bottom: $margin-small;
}

.unknown_user{
    font-weight: 400;
    color: #acacac;
}