.chatbot_selector_toggle{
    position: relative;
    font-size: 0.75rem;
    cursor: pointer;
    color: $grey;
    border: 1px solid $border-color;
    border-radius: $border-radius-small;
    padding: $padding-small $padding-medium;
    width: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: $margin-medium;
}

.chatbot_selector{
    position: absolute;
    top: 100%;
    background-color: white;
    animation: fadeIn 0.3s ease-in-out;
    box-shadow: 12px 0px 15px rgba(black, 0.1);
    border-radius: $border-radius-small;
    padding: 0.8rem $padding-medium;
    width: 100%;
    left: 0;
    border: 1px solid $border-color;
    margin-top: 0.4rem;
}

.chatbot_list__item{
    padding: $padding-small 0px;
    cursor: pointer;
    color: $charcoal;
    font-size: 0.9rem;
    line-height: 0.9rem;
    padding-right: 2rem;
    &:hover{
        color: $primary-color;
    }

    &.selected{
        color: $primary-color;
        font-weight: 500;
    }

    &:last-child{
        border-bottom: 0px;
    }
}

.chatbot_selector__title{
    padding-bottom: $padding-small;
    font-weight: 500;
    color: #888;
    font-size: 0.9rem;
}