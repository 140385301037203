.status_indicator{
    display: flex;
    align-items: center;

    &.active{
        .status_indicator__icon{ background-color: #259c72; }
    }

    &.inactive{
        .status_indicator__icon{ background-color: #a6a6a6; }
    }
}

.status_indicator__icon{
    position: relative;
    display: flex;
    margin-right: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.status_indicator__label{
    display: flex;
    align-items: center;
}