.timeline-item {
    position: relative;
    display: block;
    background: #fff;
    padding: 1.039rem 1rem;
    transition: all .3s;
    font-size: 14px;
    border-bottom: 1px solid #f5f6f5
}

.animated-background {
    animation-duration: 35s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: #f6f7f8;
    background: linear-gradient(to right,#eeeeee 8%,#f2f2f2 18%,#eeeeee 33%)
}

.line {
    height: .8rem;
    position: relative;
    margin-bottom: .3rem
}

.loader-image {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    vertical-align: middle;
    box-shadow: 0 2.5px 5px rgba(0,0,0,.1)
}

.loader-details {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5%;
    width: 75%
}

.line:last-child {
    margin-bottom: 0
}

.line.date.animated-background {
    height: .7rem;
    max-width: 30%
}

.line.message.animated-background {
    max-width: 90%
}

.chats-preloader {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: white;
}