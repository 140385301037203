.code_block{
    background-color: #ececec;
    border-radius: 4px;
    color: $charcoal;
    overflow: hidden;
    font-size: 13px;
    line-height: 20px;
    margin: $margin-medium 0px;
    margin-top: $margin-large;

    pre{
        padding: 0px;
        margin: 0px;
        font-family: "Roboto Mono", monospace;
        display: inline-block;
        text-align: left;
        white-space: pre;
        width: 100%;
        tab-size: 4;
        hyphens: none;
        color: var(--neutral-10);
        transition-property: color;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    .code_block_content{
        display: flex;
        padding: 16px 0px;
        font-family: "Roboto Mono", monospace;
    }

    .line_numbers{
        position: sticky;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        top: 0px;
        left: 0px;
        min-width: 36px;
        padding-left: 8px;
        margin-right: 1rem;
        border-right: 1px solid $border-color;
        font-family: "Roboto Mono", monospace;
        letter-spacing: -1px;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        user-select: none;
    }

    .line_number{
        pointer-events: none;
        color: #858585;
        display: block;
        padding-right: 0.8em;
        text-align: right;
    }

    .code_block_top_bar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        height: 40px;
        border-bottom: 1px solid $border-color;

        .code_block_label{
            font-size: 14px;
            font-weight: 600;
        }

        .code_block_actions{
            button{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
                border-radius: 4px;
                transition: all 0.3s;
                padding: 0px;
                border: none;
                cursor: pointer;
                background-color: transparent;
                &:hover{
                    background-color: #e0e0e0;
                }

                &:active{
                    background-color: #a6a6a6;
                }
            }
        }
    }
}