.badge{
    display: inline-block;
    padding: 0.5rem $padding-medium;
    background-color: rgba($primary-color, 0.2);
    color: $charcoal;
    width: fit-content;
    height: fit-content;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: $border-radius-small;
    cursor: default;
    margin-right: 0.2rem;

    &:last-child{
        margin-right: 0px;
    }

    &.disabled{
        background-color: rgba(red, 0.15);
        color: darken(red, 20%)
    }
}

.small_badge{
    @extend .queue_badge;
    margin: 0px !important;

    &.success{
        background-color: rgba($online-color, 0.2);
    }
}