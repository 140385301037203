.filters{
    display: flex;
    flex-grow: 1;

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
        width: 100%;
    }
}

.filters_column{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.top_bar_filter{
    flex-shrink: 0;
    margin-right: $margin-small;
    flex-grow: 1;
    max-width: 230px;
    .filter{
        margin-bottom: 0px;
        margin-right: 0px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        margin-bottom: $margin-small;
    }
}

.filter{
    margin-right: $margin-small;
    flex-grow: 1;
    width: 100%;
    &:last-child{
        margin-right: 0px;
    }
}