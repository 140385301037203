.quick_message{
    padding: 0px;
    max-width: 600px;
    border-top: 0px;
    textarea{
        border-radius: $border-radius-large !important;
    }
}

.quick_message__outer{
    .chat_media_preview{
        box-shadow: none;
        justify-content: flex-start;
        background-color: transparent;

        .chat_message_bar__message_bar__media{
            padding:  0px;
        }
    }

    .queue_badge.timer{
        margin-bottom: 0px;
        margin-left: $margin-small;
    }
}