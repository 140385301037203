button-base{
    @extend button;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    &:focus{
        outline: none;
    }

    &:disabled{
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }
    
    &.button_fit{
        width: fit-content;
    }
}

.button{
    @extend button-base;
    position: relative;
    width: 100%;
    background-color: $primary-color;
    padding: 0.65rem 0.5rem;
    color: white !important;
    border-radius: $border-radius-small;
    transition: background 0.3s ease-in-out;

    &:hover{
        background-color: darken($primary-color, 10%);
    }   
    
    &.cancel{
        background-color: $charcoal;
    }
}

.btn{
    @extend button-base;
    padding: 0px 1rem;
    font-size: 0.8rem;
    line-height: 1.1rem;
    border-radius: $border-radius-medium;
    background-color: rgba($primary-color, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 30px;
    color: $primary-color;

    &:hover{
        border-color: $primary-color;
        background-color: rgba($primary-color, 0.12);
    }

    svg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0.3rem;
        font-size: 1.1rem;
        line-height: 1.1rem;
    }

    &.cancel{
        background-color: $charcoal;
        color: white;

        &:hover{
            border-color: $charcoal;
        }
    }

    &.primary{
        color: white;
        border-color: rgba($primary-color, 0.8);
        background-color: rgba($primary-color, 0.8);

        &:hover{
            background-color: rgba($primary-color, 0.9);
            color: white;
            border-color: $primary-color;
        }
    }
}
