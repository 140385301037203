

.internal_switch{
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    border-radius: $border-radius-large;
    overflow: hidden;
}

.internal_switch__button{
    display: block;
    background-color: rgba($primary-color, 0.05);
    padding: $padding-small $padding-medium;
    font-size: 0.7rem;
    cursor: pointer;
    transition: all 0.1s;
    font-weight: 600;
    color: $primary-color;

    &:hover{
        background-color: rgba($primary-color, 0.1);
    }

    &:first-child{
        border-right: 0px;
    }
    
    &:last-child{
        border-left: 0px;
    }

    &.active{
        background-color: $primary-color;
        color: white;
        border-color: $primary-color;
    }
}
