.quick_replies{
    position: relative;
    background-color: white;
}

.quick_reply_list__title{
    font-weight: 500;
    margin-bottom: $margin-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quick_reply_list__title__error{
    color: #777;
}

.quick_reply_list{
    border: 1px solid $border-color;
    border-radius: $border-radius-small;
    max-height: 50vh;
    overflow-y: auto;
}

.quick_reply_list__item{
    padding: $padding-medium;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    border-bottom: 1px solid $border-color-light;
    white-space: pre-line;
    word-break: keep-all;

    &:hover{
        background-color: $offwhite;
    }

    &:last-child{
        border-bottom: none;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.quick_reply_list__item__meta{
    display: flex;
    align-items: center;

    @media screen and (max-width: $mobile-breakpoint) {
        justify-content: space-between;
        width: 100%;
        margin-top: $margin-medium;
    }
}

.quick_reply_list__item_actions{
    display: flex;
    align-items: center;
    margin-left: $margin-medium;

    @media screen and (max-width: $mobile-breakpoint) {
        flex-grow: 1;
        justify-content: flex-end;
    }
}

.quick_reply_list__item__text{
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 0.8rem;
    white-space: 'pre-line';
    word-break: break-all;
}

.quick_reply_list__item__attachment{
    display: flex;
    align-items: center;
    background-color: $offwhite;
    border-radius: $border-radius-large;
    border: 1px solid $border-color;
    padding: 0.5rem $padding-medium;
    margin-left: $margin-small;
    font-weight: 500;
    color: $grey;
    flex-shrink: 1;
    svg{
        margin-left: 0.2rem;
        font-size: 0.7rem;
    }

    span{
        font-size: 0.7rem;
        line-height: 0.8rem;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0rem;
        max-width: 70%;
    }
}

.quick_reply_field_badge{
    display: inline-block;
    background-color: rgba($primary-color, 0.7);
    color: white;
    padding: 0.1rem 0.3rem;
    font-size: 0.7rem;
    border-radius: $border-radius-small;
    box-shadow: -2.5px 2.5px 5px rgba(black, 0.1);
    margin-bottom: 0.1rem;
}

.quick_replies_top_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding-medium 0px;
    input { margin-bottom: 0px; max-width: 30vw; width: 300px; flex-shrink: 1; }
    button{
        flex-shrink: 0;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column-reverse;
        align-items: flex-start;

        .btn{
            margin-bottom: $margin-medium;
        }

        input{
            max-width: unset;
            width: 100%;
        }
    }
}

.quick_reply_list__item__action{
    flex-shrink: 0;
    font-size: 1.1rem;
    color: $grey;
    cursor: pointer;    
    margin-right: 1rem;
    &:last-child{
        margin-right: 0px;
    }
}

.quick_reply_badge{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba($online-color, 0.7);
    width: 30px;
    height: 30px;
    margin-right: $margin-small;
    border-radius: 50%;
    flex-shrink: 0;
    svg{
        font-size: 0.9rem;
        line-height: 0.9rem;
    }
    &.private{
        background-color: rgba($charcoal, 0.6);
    }
}