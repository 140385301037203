.chat_action_widget{
    position: absolute;
    background-color: white;
    border: 1px solid #f5f6f5;
    top: 100%;
    right: 0;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 0.6rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 300px;
    overflow: hidden;
    animation: fadeIn 0.3s;
    padding: 1rem;
}

.widget_body__copy{
    display: flex;
    align-items: center;
    color: $primary-color;
    text-decoration: none;
    font-weight: 600;
    margin-bottom: $margin-large;
    cursor: pointer;
    
    svg{
        font-size: 1.2rem;
        align-items: center;
        justify-content: center;
        margin-right: 0.1rem;
    }
}

.chat_action_widget__actions{
    display: flex;
    justify-content: space-between;

    .btn{
        flex-grow: 1;
        flex-basis: 0;
        margin-right: $margin-small;
        &:last-child{
            margin-right: 0px;
        }
    }
}

.chat_action_widget .widget_title {
    border-bottom: 1px solid #f5f6f5;
    padding: 0.5rem 1rem;
    margin: -1rem -1rem 0.8rem -1rem;
    font-size: 0.8rem;
    background-color: #f7f7f7;
    cursor: default;
    display: flex;
    align-items: center;
    svg{
        margin-right: $margin-small;
        font-size: 1rem;
    }
}

.widget_body{
    font-size: 0.8rem;
    color: #777;
    margin-bottom: $margin-medium;
}

input{
    margin-bottom: 0.8rem;
}