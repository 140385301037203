.help_request_container{
    position: relative;
    display: block;
    margin-top: $margin-large;
    margin-bottom: $margin-large;
}

.help_request_item{
    position: relative;
    display: flex;
    padding: $padding-large 0px;
    border-top: 1px solid $border-color;

    &:last-child{
        border-bottom: 1px solid $border-color;
    }

    .widget{
        width: 30%;
        margin-right: $margin-medium;
        height: fit-content;
    }

    .btn{
        margin-right: $margin-small;
        &:last-child{
            margin-right: 0px;
        }
    }

    .message_media_item__other{
        max-width: 150px;
        border-radius: $border-radius-medium;
        margin-bottom: $margin-large;
    }

    .queue_badge{
        margin: 0px;
        margin-bottom: $margin-medium;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;

        .widget{
            width: 100%;
        }
    }
}

.help_request_info{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.help_request_item_container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.help_request_info__title{
    font-weight: bold;
    font-size: 1.2rem;
}

.help_request_info__content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: $margin-medium;

    .quick_message{
        margin-bottom: $margin-medium;
    }

    > .timestamp{
        margin-bottom: $margin-small;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-right: 0px;
    }
}

.help_request_resolved__label{
    position: absolute;
    display: flex;
    border-top-right-radius: $border-radius-medium;
    align-items: center;
    top: 0%;
    right: 0%;
    padding: $padding-medium;
    z-index: 22;
    background-color: $online-color;
    color: white;
    font-size: 0.8rem;
    line-height: 0.8rem;
    border-bottom-left-radius: $border-radius-medium;
}

.help_request_info__text{
    margin-bottom: $margin-medium;
    white-space: pre-line;
    word-break: break-word;
}

.help_request_actions{
    position: relative;
    display: flex;
    justify-self: flex-end;
    margin-right: $margin-medium;
    margin-top: $margin-medium;

    @media screen and (max-width: $mobile-breakpoint) {
        margin-bottom: $margin-medium;

        .btn span{
            display: none;
        }
    }
}

.help_request_actions_container{
    display: flex;
    flex-grow: 1;
    justify-self: flex-end;
}

.resolved_by{
    position: relative;
    max-width: 300px;
    margin-top: $margin-medium;
    .resolved_by__title{
        position: relative;
        display: flex;
        background-color: $online-color;
        margin-bottom: 0px;
        padding: 0.4rem $padding-medium;
        color: white;
        align-items: center;
        font-weight: bold;
        border-top-right-radius: $border-radius-small;
        border-top-left-radius: $border-radius-small;
        font-size: 0.8rem;
        svg{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.1rem;
            margin-right: 0.3rem;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        max-width: unset;
    }
}

.resolved_by_inner{
    align-items: center;
    margin-bottom: $margin-medium;
    border: 1px solid $border-color;
    border-radius: $border-radius-small;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-bottom: 0px;

    .agent_profile_avatar{
        margin-left: 0px;
        margin-bottom: 0px;
    }

    .popup_box{
        margin-bottom: 0px;
    }

    .timestamp{
        flex-shrink: 0;
    }
}

.resolved_by__details{
    flex-grow: 1;
}

.resolved_by__details__name{
    font-weight: 500;
}

.help_request_single{
    .help_request_item{
        border: none;
    }
}

.help_request_top_bar{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: $margin-medium;

    @media screen and (max-width: $tablet-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        display: none;
        &.mobile_filters_shown{
            display: flex;
        }
    }
}