.conversation_card{
    position: relative;
    padding: $padding-medium;
    width: 300px;
    max-width: 100%;
}

.conversation_title{
    position: relative;
    font-weight: 500;
    padding-bottom: $padding-small;
    border-bottom:  1px solid $border-color-light;
    margin-bottom: $margin-medium;
    text-align: left;
}

.conversation_info{
    display: flex;
    align-items: center;

    .chat_channel_icon{
        margin-bottom: 0px;
        margin-right: $margin-small;
    }

    .btn{
        width: fit-content;
        font-size: 0.6rem;
    }
}

.conversation_details{
    position: relative;
    display: block;
    overflow: hidden;
}

.conversation_details_name{
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 0.9rem;
    margin-bottom: $margin-small;
}