.login_page {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 0 100%;
    background-color: white;
}

.login_page__container {
    background: white;
    width: 95%;
    max-width: 1000px;
    height: 85%;
    display: flex;
    position: relative;
    max-height: 600px;
    border-radius: $border-radius-large;
    overflow: hidden;
    box-shadow: 45px 25px 145px rgba(0,0,0,.15);
    .container{
        
        align-items: center;
        justify-content: center;
    }

    &::before{
        position: absolute;
        width: 50%;
        top: 0;
        height: 100%;
        content: "";
        background: rgba($primary-color, 1);
        left: 0;
        @media(max-width: $mobile-breakpoint){
            display: none;
        }
    }
}

img.login_page__image {
    display: block;
    max-width: 400px;
    margin: auto;
    padding: 0px $padding-large;
    @media(max-width: $mobile-breakpoint){
        display: none;
    }
}

.login_page_container_text_overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

.login_page_container_text_overlay_sub{
    font-weight: 300;
    font-size: 1.2rem;
}

.login_page_container_text_overlay_title{
    font-weight: 300;
    font-size: 1.8rem;
    margin-bottom: 15px;
}

.login_page_container_text_overlay_text{
    font-size: 0.8rem;
    max-width: 400px;
}

img.login_page__logo{
    display: block;
    max-width: 190px;
    margin: auto;
    margin-bottom: $margin-large;
}