.modal_flow_response__response{
    background-color: $grey;
    border-radius: $border-radius-large;
    box-shadow: inset 0px 15px 25px rgba(black, 0.2);
    color: white;
    overflow: hidden;

    .modal_flow_response__response_label{
        text-align: center;
        background-color: darken($grey, 10%);
        padding: 0.3rem 0px;
        font-size: 0.8rem;
        font-weight: 500;
    }

    pre{
        padding: $padding-medium;
        padding-top: 0px;
    }
}