.emoji_picker{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    &:hover{
        .emoji_picker__triger{
            color: $primary-color;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        display: none;
    }
}

.emoji_picker__triger{
    @extend .chat_message_bar__action;
}

aside.EmojiPickerReact{
    position: absolute !important;
    bottom: 100%;
    margin-bottom: 1rem;
    z-index: 22;
}