.status_badge{
    width: fit-content;
    background-color: rgba(grey, 0.3);
    padding: 0.5rem;
    font-size: 0.6rem;
    font-weight: 700;
    border-radius: 0.3rem;
    width: 100%;
    text-align: center;
    max-width: 80px;

    &.in_progress{
        background-color: rgba(orange, 0.3);
        color: darken(orange, 20%)
    }

    &.complete, &.approved{
        background-color: rgba($online-color, 0.3);
        color: darken($online-color, 20%);
    }

    &.failed, &.rejected{
        background-color: rgba($warning-red, 0.3);
        color: darken($warning-red, 40%);
    }
}

.job_name{
    color: $charcoal;
    font-weight: 600;
    margin-bottom: 0.1rem;
}

.job_view_logs{
    text-decoration: underline;
    cursor: pointer;
    color: $charcoal;
    transition: all 0.2s ease;

    &:hover{
        color: darken($charcoal, 25%)
    }
}

.job_decription{
    color: #888;

    .job_decription__filters_applied{
        position: relative;
        display: block;
        width: fit-content;
        color: $primary-color;
        font-weight: 600;
        @extend a;
        cursor: pointer;
    }
}

