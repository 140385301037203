@font-face {
  font-family: 'Gellix';
  font-weight: normal;
  src: url('./fonts/gellix/GellixRegular.otf');
}

@font-face {
  font-family: 'Gellix';
  font-weight: 500;
  src: url('./fonts/gellix/GellixMedium.otf');
}

@font-face {
  font-family: 'Gellix';
  font-weight: 600;
  src: url('./fonts/gellix/GellixSemiBold.otf');
}

@font-face {
  font-family: 'Gellix';
  font-weight: bold;
  src: url('./fonts/gellix/GellixBold.otf');
}

body {
  margin: 0;
  font-family: -apple-system, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b, strong, h1, h2, h3, h4, h5, h6, .page_title{
  font-family: Gellix, -apple-system, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

