.leads_top_bar{
    display: flex;
    justify-content: space-between;

    input{
        margin-bottom: 0px;
    }

    .form-group{
        width: fit-content;
        margin-right: 1rem;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        display: none;
        flex-direction: column-reverse;

        &.mobile_filters_shown{
            display: flex;
        }
    }
}



.leads_info_none{
    color: #777;
    font-style: italic;
    font-size: 0.8rem;
}

.leads_search .form-group{
    display: flex;

    .btn{
        border-radius: .25rem;
        margin-left: $margin-small;
    }
}

.leads_list{
    overflow-x: auto;
    font-size: 0.8rem;

    table{
        .btn{
            white-space: nowrap;
        }
    }
}