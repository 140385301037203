.message__media{
    position: relative;
    display: flex;
    flex-direction: column;

    audio{
        outline: none;
        border: none;
    }
}

.message_media_item__preloader{
    position: relative;
    height: 400px;
    width: 400px;
    cursor: default;
}

.message_media_item__fit{
    
    position: relative;
    display: flex;
    cursor: pointer;
    justify-content: center;
    background-color: $offwhite;
    img{
        position: relative;
        object-fit: cover;
        object-position: 50%;
        background-color: $offwhite;
        max-height: 400px;
        max-width: 400px;
    }

    video{
        position: relative;
        object-fit: cover;
        object-position: 50%;
        max-height: 400px;
        max-width: 400px;
        background-color: $offwhite;
    }
}

.message_media_item__failed{
    display: flex;
    align-items: center;
    width: 100%;
    padding: $padding-medium;
    text-align: left;
    background: rgba($charcoal, 0.2);

    &.thumbnail{
        max-width: 250px;
        margin-bottom: $margin-medium;
        border-radius: $border-radius-medium;
        .message_media_item__failed__details__title{
            font-size: 0.8rem;
        }
    
        .message_media_item__failed__details__body{
            font-size: 0.7rem;
        }
    }

    svg{
        font-size: 2rem;
        color: $charcoal;
        margin-right: 0.8rem;
    }

    .message_media_item__failed__details__title{
        font-size: 1rem;
        color: darken($charcoal, 30%);
        font-weight: 500;
    }

    .message_media_item__failed__details__body{
        color: darken($charcoal, 40%);
    }
}

.message_media_item__play{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 33;
    height: 100%;
    width: 100%;
    font-size: 4rem;
    color: white;
    transition: font-size 0.2s ease-in-out;

    &:hover{
        font-size: 5rem;
    }
}

.message_media_item__fit.thumbnail{
    position: relative;
    border-radius: $border-radius-small;
    overflow: hidden;
    margin-bottom: $margin-large;
    max-width: 150px;
    max-height: 150px;
    width: fit-content;
    height: fit-content;
    img{
        max-width: 150px;
        max-height: 150px;
    }

    video{
        max-width: 150px;
        max-height: 150px;
    }

    .message_media_item__preloader{
        height: 150px;
        width: 150px;

        .preloader_text{
            font-size: 0.8rem;
        }
    }
}

.media_modal{
    position: relative;
    display: flex;
    background-color: transparent !important;
    outline: none !important;
    align-items: center;
    justify-content: center;

    img, video{
        position: relative;
        display: flex;
        max-width: 70vw;
        max-height: 80vh;
        box-shadow: 0px 15px 45px rgba(black, 0.8);
        border-radius: $border-radius-medium;
        outline: 0px;
    }
}

.message_media_item{
    position: relative;
    display: flex;
    flex-direction: column;
}

.message_media_item__other{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: $padding-medium $padding-large;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: $border-radius-large;
    background-color: rgba($light-grey, 0.5);

    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height: 1rem;
        margin-right: 0.3rem;
        flex-shrink: 0;
    }
}