.search_container{
    flex-grow: 1;
    display: flex;
    max-width: 300px;
    flex-direction: column;
}

.help_request_search {
	display: grid;
	grid-template-columns: 1fr auto auto;
	align-items: center;
	width: 100%;
	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: $margin-small;
		border-radius: 0.25rem;
		svg {
			margin: 0px;
		}
	}

	input {
		margin-bottom: 0px;
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: $mobile-breakpoint) {
		width: 100%;
		margin-bottom: $margin-medium;
		max-width: unset;
		grid-template-columns: 1fr;
		input {
			flex-grow: 1;
			width: unset;
		}
	}
}

.search{
    width: 100%;
}