$accept-color: #25d366;
$transfer-color: #ebebeb;
$decline-color: #ff4747;

.human_handoff_notification{
    position: relative;
    display: flex;
    padding: $padding-medium;
    flex-direction: column;
    border-radius: $border-radius-small;

    .queue_badge{
        margin: 0px;
        margin-bottom: 0.2rem;
    }

    &.transfer{
        background-color: white;
        animation: glow 1.5s infinite ease-out;
    }

    .agent_profile_avatar{
        margin: 0px;
        margin-right: 0.3rem;
    }

    .popup_box{
        margin: 0px;
        
    }

    .chat_transfer{
        position: relative;
        bottom: 15%;
        margin: auto;
        margin-top: $margin-medium;
    }
}

.human_handoff_notification__info{
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.human_handoff_notification__topbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-medium;
}

.timer{
    display: flex;
    align-items: center;
    font-size: 0.7rem !important;
    line-height: 0.7rem !important;
    color: darken($primary-color, 5%) !important;
    svg{
        font-size: 0.8rem;
        line-height: 0.8rem;
        display: flex;
        align-items: center;
        margin-right: 0.1rem;
    }
}

.human_handoff_notification__title{
    position: relative;
    display: flex;
    align-items: center;
    color: $grey;
    font-weight: 500;
    width: fit-content;
    font-size: 0.8rem;
    padding-bottom: 0.4rem;
    padding-right: 1rem;

    > div{
        display: flex;
        align-items: center;
        word-break: keep-all;
        margin-right: 0.2rem;
    }
}

.human_handoff_notification__info__user{
    position: relative;
    display: block;
    flex-grow: 1;
    margin-left: $margin-small;
    max-width: 75%;
    margin-right: $margin-small;
}

.human_handoff_notification__view_chat{
    font-size: 1.3rem;
    cursor: pointer;

    &:hover{
        color: $primary-color;
    }
}

.human_handoff_notification__info__user__name{
    font-weight: 500;
}

.human_handoff_actions{
    display: flex;
    justify-content: space-evenly;
}

.human_handoff_action{
    flex-grow: 1;
    color: white;
    text-align: center;
    padding: 0.5rem 0px;
    font-size: 0.7rem;
    font-weight: 500;
    margin-right: 0.3rem;
    border-radius: $border-radius-large;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    display: block;
    &:last-child{
        margin-right: 0;
    }

    &.accept{
        background: $accept-color;
        &:hover{
            background: darken($accept-color, 5%);
        }
    }

    &.transfer{
        background: $transfer-color;
        color: $charcoal;
        &:hover{
            background: darken($transfer-color, 5%);
        }
    }

    &.decline{background: $decline-color;
        &:hover{
            background: darken($decline-color, 5%);
        }
    }
}

.human_handoff_notification__overlay{
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    left: 0;
    top: 0;
    z-index: 2;
}