.company_select_page{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: $background-color;
}

.company_select_page__container{
    position: relative;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    padding: $padding-large;
    border: 1px solid $light-grey;
    border-radius: $border-radius-medium;
    width: 90%;
    max-width: 400px;
    max-height: 80%;
    display: flex;
    align-items: center;
    background-color: white;
    border-top: 5px solid $primary-color;

    > div{
        width: 100%;
        > div {
            width: 100%;
        }
    }
}

.company_list__inner{
    display: block;
    border: 1px solid $light-grey;
    width: 100%;
    border-radius: $border-radius-medium;
    overflow: hidden;
    margin-top: $margin-large;
    max-height: 50vh;
    overflow-y: auto;
    margin-bottom: $margin-large;
}

.company_list__item{
    padding: 0.7rem $padding-medium;
    border-bottom: 1px solid $light-grey;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &:hover{
        background-color: $offwhite;
    }

    &:last-child{
        border-bottom: none;
    }
}

.company_list__item__checkbox{
    position: relative;
    display: block;
    width: 1rem;
    height: 1rem;
    border: 1px solid $light-grey;
    border-radius: 50%;
    margin-right: $margin-medium;
    overflow: hidden;

    &.selected{
        &::before{
            position: absolute;
            width: 70%;
            height: 70%;
            content: "";
            top: 15%;
            left: 15%;
            border-radius: 50%;
            background-color: $primary-color;
        }
    }
}

.company_list__item__details{
    flex-grow: 1;
}

.company_list__item__title{
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: $margin-small;
}

.company_list__item.selected{
    .company_list__item__checkbox::before{
        position: absolute;
        width: 70%;
        height: 70%;
        content: "";
        top: 15%;
        left: 15%;
        border-radius: 50%;
        background-color: $primary-color;
    }
}