.dashboard{
    position: fixed;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.dashboard_content{
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100vw;
}

.dashboard_component{
    position: relative;
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
}