form{
    display: block;
}

.form-group__split{
    display: flex;
    justify-content: space-between;
    .form-group{
        width: calc(50% - 0.5rem);
    }

    @media (max-width: $mobile-breakpoint){
        display: block;
        .form-group{
            width: 100%;
        }
    }
}

.select-label{
    font-weight: 500;
    margin: 0px 0.2rem;
}

.form_select{
    font-size: 0.8rem;
    input{
        margin-bottom: 0px;
    }

    margin-bottom: $margin-medium;
}

.form-group{
    position: relative;
    width: 100%;
    margin-bottom: $margin-medium;

    .input-field{
        display: block;
    }
}

@function property-value($property, $selector: null) {
    @return $magic;
}

.contained_input{
    position: relative;
    input{
        position: relative;
        padding-right: 52px !important;
    }
    .max-length{
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        right: 0;
        color: rgba(grey, 0.75);
        padding: 0px 0.6rem;
        font-size: 0.8rem;
        cursor: default;
    }
}

label{
    font-size: 0.8rem;
    color: $grey;    
    margin-bottom: 0.3rem;
    display: block;
    color: #888;
}

.input-field{
    outline: none;
    width: 100%;
    line-height: 1.5rem;
    padding: .4rem .75rem;
    border: 1px solid $border-color;
    border-radius: .25rem;
}

input[type="email"], input[type="password"], input[type="text"], input[type="number"]{
    font-family: inherit;
    -webkit-appearance: none;
}

textarea{
    @extend .input-field;
    font-family: inherit;
    resize: none;
    -webkit-appearance: none;
}

select{
    @extend .input-field;
    font-family: inherit;
    -webkit-appearance: none;
}

.custom_select__control{
    cursor: pointer !important;
    transition: box-shadow 0.5s ease;
    outline: none;
    border: 1px solid $border-color !important;

    &:hover{
        outline: none !important;
        box-shadow: inset 0px 0px 3px rgba(black, 0.1);
    }

    &.custom_select__control--is-focused{
        box-shadow: none;
        box-shadow: inset 0px 0px 3px rgba($primary-color, 0.1);
        border: 1px solid $border-color !important;
        outline: none !important;
    }

    &.custom_select__control--menu-is-open{
        box-shadow: none;
        border: 1px solid $primary-color !important;
        outline: none !important;
    }

    &:hover{
        box-shadow: inset 0px 0px 3px rgba(black, 0.1);
    }
}

.custom_select__menu{
    animation: fadeIn 0.3s ease;
    border-radius: $border-radius-medium !important;
}

.custom_select__menu-list{
    padding: 0.3rem !important;
}


.custom_select__option{
    cursor: pointer !important;
    margin-bottom: 0.3rem !important;
    border-radius: $border-radius-small !important;

    &.custom_select__option--is-focused {
        background-color: rgba($charcoal, 0.05)
    }

    &.custom_select__option--is-selected{
        color: black;
        background-color: rgba($primary-color, 0.1);

        &.custom_select__option--is-focused{
            background-color: rgba($primary-color, 0.2);
        }
    }

    &:last-child{
        margin-bottom: 0px !important;
    }
}

.custom_select__indicator-separator{
    background-color: $border-color-light !important;
}

.custom_select__indicator{
    color: rgba($charcoal, 0.7) !important;
    padding: 6px;
}

.custom_select__value-container{
}

.help-block{
    font-size: 0.8rem;
    color: $warning-red;
}

.toggle_box{
    display: flex;
    align-items: center;

    svg{
        font-size: 1.2rem;
        margin-right: 0.3rem;
    }
}