.notifications{
    position: relative;
    display: block;
}

.notifications_trigger{
    position: relative;
    display: flex;
    font-size: 1.2rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    color: $charcoal;

    &:hover{
        background-color: $offwhite;
    }

    .count_badge{
        top: 0;
    }
}

.notifications_top_container{
    position: absolute;
    right: 0;
    width: 425px;
    top: 100%;
    margin: 0px -40px;
    padding: 0px 40px;
    margin-bottom: 0px;
    margin-top: 0.3rem;
    border-radius: $border-radius-small;
    animation: fadeIn 0.3s;
    max-height: calc(100vh - 51px);
    overflow-x: hidden;
    padding-bottom: 2.5rem;
    overflow-y: auto;

    @media screen and (max-width: $mobile-breakpoint) {
        position: fixed;
        left: 0;
        right: unset;
        top: 50px;
        width: 100%;
        height: calc(100% - 51px);
        margin: auto;
        margin-top: 0px;
        padding: $padding-medium;
    }
}

.notifications_actions__container{
    display: flex;
    align-items: center;
}

.notifications_container{
    position: relative;
    right: 0;
    width: 100%;
    margin-top: 0.3rem;
    background: white;
    border-radius: $border-radius-medium;
    animation: fadeIn 0.3s;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        top: unset;
    }
}

.incoming_meeting_action_container{
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    flex-grow: 1;
}

.notifications_container__inner{
    max-height: 80vh;
    overflow-y: auto;
}

.notifications_actions{
    display: flex;
    padding: 0.5rem $padding-medium;
    border-bottom: 1px solid $border-color-light;
    justify-content: space-between;
    align-items: center;
}