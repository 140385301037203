.error_item{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    margin-bottom: $margin-medium;

    &:last-child{
        border-bottom: 0px;
        margin-bottom: 0px;
    }
}

.error_details{
    flex-grow: 1;
    max-width: 70%;
}

.error_meta{
    width: 30%;
    flex-shrink: 0;
    margin-left: $margin-medium;
}

.error_meta_item{
    margin-bottom: $margin-medium;
}

.error_trace{
    width: 100%;
    overflow: hidden;
    white-space: pre-line;
    word-break: break-all;
    margin-top: $margin-medium;
}

.error_item__actions{
    display: flex;
    align-items: center;
    
    .btn{
        margin-right: $margin-small;
        &:last-child{
            margin-right: 0px;
        }
    }
}