.chat_top_bar{
    display: flex;
    background: white;
    border-bottom: 1px solid $border-color-light;
    padding: 0 $padding-medium;
    min-height: 60px;
    align-items: center;
    z-index: 1;
}

.chat_top_bar__username{
    display: flex;
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 500;
    align-items: center;
    margin-bottom: 0.2rem;

    .name{
        max-width: 300px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;

        .queue_badge{
            margin-left: 0px;
            margin-top: 0.5rem;
            margin-bottom: 0px;
            background-color: transparent;
            padding: 0px;
            font-size: 0.7rem;
            color: #888;
            font-weight: 500;
        }
    }
}

.chat_top_bar_back{
    display: none;
    @media screen and (max-width: $mobile-breakpoint) {
        display: flex;
        padding-right: $padding-medium;
        cursor: pointer;
        font-size: 1.5rem;
        line-height: 1.5rem;
        align-items: center;
        justify-content: center;
    }
}

.chat_top_bar_details{
    flex-grow: 1;
}

.chat_top_bar__created{
    font-size: .8rem;
    line-height: .8rem;
    color: $grey;

    @media screen and (max-width: $mobile-breakpoint) {
        display: none;
    }
}