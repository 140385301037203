.ReactModalPortal .ReactModal__Overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    background: rgba(black, 0.5) !important;
}

.modal{
    position: relative;
    background: white;
    align-self: center;
    padding: 2.5rem;
    border-radius: $border-radius-medium;
    width: 700px;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    outline: none !important;
    margin: 0px 1rem;
    box-shadow: 7.5px 15px 40px rgba(black, 0.1);
    overflow-y: auto;
    
    &:first-child:before {
        content:'';
        position:absolute;
        width:100%;
        height:5px;
        background:linear-gradient(to right, $primary-color, $secondary-color 100%);
        top: 0px;
        left:0;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 1.5rem 1.2rem;
    }
}

.media_modal__close{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -0.6rem;
    right: -0.6rem;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    color: white;
    z-index: 222;
    background-color: $warning-red;
    border-radius: 50%;
}

.user_info_modal__content{
    max-height: 60vh;
    overflow-y: auto
}

.action_modal{
    @extend .modal;
    width: 95vw;
    max-width: $contained-width;
    max-height: 80vh;

    @media screen and (max-width: $mobile-breakpoint) {
        max-height: 70vh;
    }

    .container{
        margin-bottom: $margin-medium;
    }

    .chat_media_preview{
        padding: 0px;
        box-shadow: none;
        background-color: white;
        margin-top: $margin-medium;
        align-items: flex-start;
        height: unset;

        .chat_media_preview__container{
            max-width: unset !important;
            width: 100%;
            height: unset;
        }

        .chat_media_preview__container__default{
            padding: $padding-medium 0px;
        }

        .chat_media_preview__container img, .chat_media_preview__container video, .chat_media_preview__container .chat_media_preview__container__default{
            height: unset;
        }
    }
}

.modal__title{
    display: flex;
    font-weight: 300;
    font-size: 2rem;
    padding-bottom: $padding-large;
    justify-content: space-between;
    align-items: center;

    .agent_profile_avatar{
        margin-bottom: 0px;
    }
}

.modal__subtitle{
    margin-bottom: $margin-medium;
    color: #777;
    font-weight: 500;
}

.modal__content{
    color: $charcoal;
    margin-bottom: $margin-medium;
}

.modal__actions{
    display: flex;
    justify-content: flex-end;
    padding-top: $padding-large;
    button{
        margin-right: 0.5rem;

        &:last-child{
            margin-right: 0px;
        }
    }
}