.webchat_theme_editor{
    display: flex;
    width: 100%;
}

.webchat_preview_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $offwhite;
    flex-grow: 1;
    margin-left: $margin-large;
    padding: $padding-medium 30px;
    border-radius: $border-radius-medium;
}

.webchat_preview{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: $margin-large;
    margin-top: $margin-medium;
    width: 280px;
    height: 450px;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: rgba(13, 13, 13, 0.12) 0px 1px 5px 0px, rgba(13, 13, 13, 0.16) 0px 0px 1px 0px, rgba(13, 13, 13, 0.08) 0px 2px 2px 0px;
}

.webchat_theme_editor_preview_title{
    font-weight: 600;
    font-weight: $charcoal;
}

.webchat_header{
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0px 12px;
}

.webchat_header_bot_name{
    font-weight: 700;
    color: white;
    font-size: 16px;
}

.webchat_header_bot_avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
    object-position: center;
}

.webchat_header_image{
    max-width: 50%;
    max-height: 100%;
}

.webchat_body{
    padding: $padding-medium;
    flex-grow: 1;
}

.webchat_input{
    margin: $padding-medium;
    background-color: #f2f2f2;
    padding: 8px $padding-medium;
    border-radius: 30px;
}

.webchat_preview_message{
    display: flex;
    
    &.user{
        justify-content: flex-end;

        .webchat_preview_message__content{
            color: white;
        }
    }
}

.webchat_preview__expand{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    color: $charcoal;
    cursor: pointer;
    border-radius: 50%;
    align-self: flex-end;
    margin-right: $margin-medium;
    color: white;
    svg{
        margin-right: 0px;
        font-size: 25px;
    }

}

.webchat_preview_message__content{
    background-color: #f0f2f7;
    border-radius: 20px;
    padding: 8px 12px;
    margin-bottom: $margin-medium;
}