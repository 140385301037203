.internal_chat_new_chat{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
}

.internal_agent_list{
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
}

.internal_agent_list_item{
    display: flex;
    align-items: center;
    padding: $padding-medium;
    border-bottom: 1px solid $border-color-light;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &:hover{
        background-color: $offwhite;
    }

    .agent_profile_avatar{
        margin-bottom: 0px;
    }

    &:last-child{
        border-bottom: 0px;
    }
}

.internal_chat_widget__content_inner{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.internal_agent_list_item__details__name{
    font-size: 1.1rem;
    margin-bottom: 0.2rem;
    font-weight: 500;
}

.internal_chat_widget__title__actions{
    display: flex;
    align-items: center;
}