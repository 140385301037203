.meeting_room_call{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $charcoal;
    

    @media screen and (max-height: 500px) and (max-width: $mobile-breakpoint){
        &.screen_share{
            .meeting_room_video_grid{
                display: none;
            }
    
            .screen_share__container{
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

.meeting_room_call .meeting_room_call__local_video_preview{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 22;
    video{
        overflow: hidden;
        box-shadow: 15px 15px 30px rgba(black, 0.1);
        max-height: 25vh;
        max-width: 25vh;
        object-position: 50%;
        object-fit: cover;
    }

    @media screen and (max-width: $mobile-breakpoint) and (min-height: 500px) {
        video{
            max-height: 20vh;
            max-width: 15vh;
        }
    }

    @media screen and (max-height: 500px) and (max-width: $mobile-breakpoint){
        video{
            max-height: 20vh;
            max-width: 35vh;
        }
    }
}

.meeting_room_call__participants{
    width: 300px;
    background-color: white;
    flex-shrink: 0;
}

.switch_camera{
    display: none !important;

    @media screen and (max-width: $mobile-breakpoint) {
        display: flex !important;
    }
}

.meeting_alert_message{
    position: fixed;
    bottom: 5%;
    left: 5%;
    max-width: 300px;
    font-size: 0.8rem;
    background-color: rgba(white, 0.8);
    padding: $padding-medium;
    border-radius: $border-radius-small;
    border-top: 5px solid $primary-color;
    box-shadow: 0px 0px 30px rgba(black, 0.4);
    animation: fadeIn 2s ease;

    .meeting_alert_message__button{
        display: flex;
        margin-top: $margin-medium;
        align-items: center;
        justify-content: center;

        svg{
            margin-right: 0.3rem;
            font-size: 0.9rem;
        }
    }
}

.meeting_room_call__call_actions{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    right: 0;
    top: 0;
    padding: $padding-large;

    @media screen and (max-width: $mobile-breakpoint){
        flex-direction: column-reverse;
        padding: $padding-medium;

        button{
            margin-right: 0px !important;
            margin-bottom: $margin-small;

            &:first-child{
                margin-bottom: 0;
            }
        }
    }

    .local_settings{
        position: absolute;
        display: block;
        top: 100%;
        background-color: rgba($charcoal, 0.8);
        padding: $padding-medium;
        width: 90vw;
        max-width: 300px;
        border-radius: $border-radius-medium;
        text-align: left;
        margin-top: -0.5rem;
        box-shadow: 0px 0px 30px rgba(black, 0.4);
        border: 1px solid $border-color;

        label{
            color: rgba(white, 0.8);
        }

        .select-item{
            position: relative;
            margin-bottom: $margin-small;
            
            &:last-child{
                margin-bottom: 0px;
            }
        }

        @media screen and (max-width: $mobile-breakpoint){
            position: fixed;
            bottom: 0%;
            left: 0;
            width: 100%;
            top: unset;
            max-width: 100%;
            background-color: white;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-bottom: 4px solid $primary-color;
            z-index: 222;
            label{
                color: #777;
            }
        }
    }
    
    button{
        position: relative;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        margin-right: $margin-medium;
        border: 0px;
        border-radius: 50%;
        box-shadow: 0px 0px 15px rgba(black, 0.2);
        cursor: pointer;
        outline: 0px;
        transition: all .2s;
        background-color: white;
        &:hover{
            background: darken(white, 15%);

            @media screen and (max-width: $mobile-breakpoint) {
                background: white;
            }
        }

        &:last-child{
            margin-right: 0px;
        }

        &[disabled]{
            cursor: not-allowed;
            &:hover{
                background-color: white;
            }
        }

        svg{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            line-height: 1.2rem;
        }

        &.leave{
            background-color: $warning-red;
            color: white;
            &:hover{
                background: darken($warning-red, 5%);

                @media screen and (max-width: $mobile-breakpoint) {
                    background-color: $warning-red;
                }
            }
        }
    }
}

.screen_share__container{
    position: relative;
    display: block;
    height: 85vh;
    background-color: darken($charcoal, 4%);

    @media screen and (max-width: $mobile-breakpoint) {
        height: 80vh;
    }
}

.meeting_room_video_grid__item.screen_share{
    width: 100%;
    height: 100%;
    video{
        object-fit: contain !important;
    }
}

.meeting_room_video_grid{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    flex-shrink: 0;

    &.grid_1 .meeting_room_video_grid__item{
        width: 100%;
    }

    &.grid_2 {
        .meeting_room_video_grid__item{
            width: 50%;
        }

        @media screen and (max-width: $mobile-breakpoint) and (min-height: 500px){
            flex-direction: column;
            .meeting_room_video_grid__item{
                width: 100%;
                height: 50%;
            }
        }
    }

    &.grid_3 {
        flex-direction: column;
        flex-wrap: wrap;
        .meeting_room_video_grid__item{
            width: 50%;
            height: 50%;
            &:first-child{
                height: 100%;
            }
        }

        @media screen and (max-width: $mobile-breakpoint) and (min-height: 500px){
            .meeting_room_video_grid__item{
                width: 100%;
                height: 33.333333% !important;
            }
        }
    }

    &.grid_4 {
        flex-wrap: wrap;
        .meeting_room_video_grid__item{
            width: 50%;
            height: 50%;
        }
    }

    &.grid_5 {
        flex-direction: column;
        flex-wrap: wrap;
        .meeting_room_video_grid__item{
            width: 33.333%;
            height: 50%;
            &:first-child{
                height: 100%;
            }
        }

        @media screen and (max-width: $mobile-breakpoint) and (min-height: 500px){
            .meeting_room_video_grid__item{
                width: 50%;
                height: 33.333333% !important;
                &:first-child{
                    width: 100%;
                    height: 33.3%;
                }
            }
        }
    }

    &.grid_6 {
        flex-wrap: wrap;
        .meeting_room_video_grid__item{
            width: 33.333%;
            height: 50%;
        }
    }

    &.grid_7 {
        flex-direction: column;
        flex-wrap: wrap;
        .meeting_room_video_grid__item{
            width: 25%;
            height: 50%;
            &:first-child{
                height: 100%;
            }
        }
    }

    &.grid_8 {
        flex-wrap: wrap;
        .meeting_room_video_grid__item{
            width: 25%;
            height: 50%;
        }
    }

    &.grid_9 {
        flex-wrap: wrap;
        .meeting_room_video_grid__item{
            width: 33.333%;
            height: 33.333%;
        }
    }
}

.meeting_room_video_grid__item{
    video{
        object-fit: cover !important;
        object-position: 50% !important;
    }

    &.no_video {
        .meeting_room_video_grid__item__avatar_holder{
            @extend .meeting_room_video_grid__item__video;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        div[streamid]{
            display: none;
        }
    }
}

.meeting_room_video_grid__item__video{
    height: 100%;
    width: 100%;

    .chat_message_avatar{
        @extend .agent_profile_avatar;
        position: relative;
        display: flex;
        align-self: center;
        justify-content: center;
        flex-direction: column;
        width: 25%;
        padding-bottom: 25%;
        background: white;
        margin-bottom: 0;

        .chat_message_avatar__initials{
            position: absolute;
            display: flex;
            flex-grow: 1;
            width: 100%;
            align-self: center;
            justify-content: center;
            font-size: 100rem;
            color: $primary-color;
            height: 100% !important;
            top: 0%;
        }
    }
}

.avatar_box{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 600px; 
}

.meeting_room_video_grid__item{
    position: relative;
}

.meeting_room_video_grid__item__display_name{
    position: absolute;
    display: flex;
    align-items: center;
    line-height: 0.8rem;
    bottom: 0;
    max-width: 80%;
    z-index: 33;
    background-color: rgba(black, 0.4);
    padding: 0.5rem $padding-medium;
    color: white;
    font-size: 0.8rem;
    border-top-right-radius: $border-radius-small;
    max-width: 300px;
    svg{
        margin-left: 0.3rem;
        flex-shrink: 0;
    }

    @media screen and (max-width: $mobile-breakpoint){
        bottom: unset;
        top: 0;
        max-width: 90%;
    }
}

.meeting_room_video_grid__item__avatar_holder.speaking-border-for-video{
    background: radial-gradient(rgba($primary-color, 0.3), rgba($primary-color, 0.2));
    .chat_message_avatar{
        box-shadow: 0px 10px 30px rgba(black, 0.3);
        border: 0.2rem solid $primary-color;
        padding-bottom: calc(25% - 0.4rem) !important;
    }
}

.meeting__title{
    position: absolute;
    display: flex;
    left: 0;
    padding: $padding-medium;
    font-size: 1rem;
    font-weight: 500;
    background-color: white;
    border-bottom-right-radius: $border-radius-large;
    border: 1px solid $border-color-light;
    box-shadow: 5px 5px 15px rgba(black, 0.1);
    @media screen and (max-width: $mobile-breakpoint){ display: none; }
}

.speaking-border-for-display-name{
    background-color: rgba($primary-color, 0.9);
    font-weight: 500;
    color: white;
}

.meeting_room_video_grid__waiting{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .agent_profile_avatar{
        border: 3px solid darken(white, 30%);
    }
    
    label{
        color: darken(white, 30%);
    }
}

.meeting_room_video_grid__waiting__meeting_owner{
    font-weight: bold;
    font-size: 1.4rem;
    color: white;
}

.meeting_room.minimized{
    height: 12.5vw;
    width: 20vw;
    bottom: 20%;
    right: 5%;
    left: unset;
    top: unset;
    border-radius: $border-radius-small;
    overflow: hidden;
    cursor: move;

    @media screen and (max-width: $mobile-breakpoint){
        width: 40vw;
        height: 40vw;
        cursor: pointer;

        .meeting_room_call__call_actions{
            display: none;
        }
    }

    .meeting_room_call.screen_share{
        .meeting_room_video_grid{
            display: none;
        }


    }

    .meeting_alert_message{
        display: none;
    }

    .screen_share__container{
        max-height: unset !important;
        height: 100% !important;
    }

    .meeting__title{
        display: none;
    }

    .meeting_room_call__call_actions{
        justify-content: center;
        bottom: 0;
        padding: 0.5rem 0px;
        button{
            width: 35px;
            height: 35px;
            margin-right: 0.3rem;
            svg{
                font-size: 1rem;
            }
        }
    }

    .meeting_room_call__local_video_preview{
        display: none;
    }

    .meeting_room_video_grid__waiting {
        flex-grow: 1;
        .avatar-container{ display: none; }
    }

    .meeting_room_video_grid__item__display_name{
        top: 0;
        bottom: unset;
        border-top-right-radius: 0px;
        border-bottom-right-radius:  $border-radius-small;

        span{
            font-size: 0.6rem;
        }
    }
}

.meeting_room_video_grid__item__display_name{
    span{
        font-size: 0.75rem;
    }
}

.meeting_room_call.screen_share{
    .meeting_room_video_grid{
        height: 15vh;
        position: relative;
        justify-content: flex-end;
        flex-wrap: unset !important;
        flex-direction: row;
        .meeting_room_video_grid__item{
            width: 10%;
            height: 100%;
            .meeting_room_video_grid__item__video{
                width: 100% !important;
                video{
                    width: 100% !important;
                }
            }

            @media screen and (max-width: $mobile-breakpoint) {
                flex-grow: 1;
                height: 100% !important; 
            }
        }

        .meeting_room_call__local_video_preview{
            position: relative;
            width: 10%;
            @media screen and (max-width: $mobile-breakpoint) {
                width: unset;
                height: 100%;
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            height: 20vh;
        }
    }
}