.chats_sidebar{
    position: relative;
    display: flex;
    flex-shrink: 0;
    background-color: white;
    width:  350px;
    transition: width 0.3s;
    border-left: 1px solid $border-color-light;
    justify-content: space-evenly;
    overflow-y: auto;

    @media screen and (max-width: $tablet-breakpoint) {
        position: absolute;
        right: 0;
        z-index: 22;
        box-shadow: -15px 0px 30px rgba(black, 0.2);
        height: 100%;

        &.collapsed{
            position: relative;
            box-shadow: -15px 0px 30px rgba(black, 0);;
        }
    }

    @media screen and (min-width: $mobile-breakpoint) {
        &.collapsed{
            overflow-x: hidden;
            width: 50px;
            
            .preloader{
                display: none;
            }

            .widget{
                display: none;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        display: none;
        position: absolute;
        width: 100vw;
        top: 0;
        height: 100%;
        z-index: 2;
        &.mobile_expanded{
            display: flex !important;
        }
    }
}

.chats_sidebar_collapse{
    margin-bottom: $margin-small;
    display: flex;
    cursor: pointer;

    svg{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.2rem;
        height: 1.2rem;
        padding: 0.20rem;
        border-radius: 50%;
        border: 1px solid $border-color;
    }

    @media screen and (max-width: $mobile-breakpoint){
        display: none;
    }
}

.chats_sidebar_mobile_collapse{
    font-size: 1.6rem;
    margin-bottom: $margin-medium;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    @media screen and (min-width: $mobile-breakpoint){
        display: none;
    }
}

.chats_sidebar_inner{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.chats_sidebar_widgets{
    padding: $padding-medium;
    padding-bottom: 3rem
}