.mini_agent_profie_trigger{
    position: relative;
    cursor: pointer;
    padding: $padding-small;
    margin-left: $margin-small;
    &:hover{
        background-color: $offwhite;

        @media screen and (max-width: $mobile-breakpoint) {
            background-color: transparent;
        }
    }

    .agent_profile_avatar{
        margin-bottom: 0px;
        cursor: pointer;
    }
}

.mini_agent_profile__profile{
    position: absolute;
    width: 90vw;
    right: 0;
    max-width: 300px;
    background-color: white;
    margin: $margin-medium;
    box-shadow: 5px 5px 15px rgba(black, 0.2);
    padding: $padding-medium;
    border-radius: $border-radius-large;
}

.mini_agent_profie__title{
    font-weight: bold;
    margin-bottom: $margin-medium;
}

.mini_agent_profile_details{
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: $margin-medium;
    border-top: 1px solid $border-color-light;
    padding-top: $padding-medium;
    .status, .agent_profile_avatar{ margin-bottom: 0px; }
}

.mini_agent_profile_details__container{
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    padding-bottom: 300px;
    margin-bottom: -300px;
    padding-left: 0.2rem;
    margin-left: -0.2rem;
}

.mini_agent_profile_details__name{
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.mini_agent_profile__actions{
    padding-top: $padding-medium;
    border-top: 1px solid $border-color-light;
}

.mini_agent_profile__action{
    display: flex;
    align-items: center;
    padding: $padding-small;
    cursor: pointer;
    border-radius: $border-radius-medium;
    transition: all 0.3s;
    text-decoration: none;
    color: $charcoal !important;
    font-size: 0.9rem;
    line-height: 0.9rem;

    &:hover{
        background-color: $offwhite;
    }

    svg{
        color: $grey;
        font-size: 1.1rem;
        margin-right: $margin-small;
    }
}