.settings_container{
    border: 1px solid $border-color;
    border-radius: $border-radius-medium;
}

.settings_row{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color;

    &:last-child{
        border-bottom: 0px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
    }
}

.settings_col{
    padding: $padding-large $padding-medium;
}

.settings_col.setting_name{
    width: 70%;
    border-right: 1px solid $border-color;
    flex-shrink: 0;

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        border-right: 0px;
        padding-bottom: 0px;
    }
}

.setting_name__name{
    font-weight: bold;
    margin-bottom: $margin-small;
}

.setting_name__body{
    color: #777;
    font-size: 0.8rem;
}

.setting_value{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    input{
        margin: 0;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        align-items: flex-start;
        width: 100%;
    }
}