.dashboard_menu{
    position: relative;
    display: flex;
    flex-shrink: 0;
    height: 100%;
    width: 4rem;
    background: linear-gradient($primary-color, #0087BA 100%);
    box-shadow: 0 0 5px rgba(0,0,0,.1);
    z-index: 222;
    padding: 0.8rem;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: $mobile-breakpoint) {
        position: absolute;
        justify-content: flex-start;
        width: 100%;
        display: none;      
        background: white;
        
        .dashboard_menu__logo{
            display: none;
        }

        .dashboard_menu__item__label{
            position: relative;
            display: flex;
            left: 0;
            span{
                padding: 0px;
                background-color: transparent;
                color: $charcoal;
                font-size: 0.9rem;
                line-height: 0.9rem;
            }
        }

        &.mobile_expanded{
            display: flex;
        }
    }
}

.dashboard_menu__top_bar{
    display: none;
    @media screen and (max-width: $mobile-breakpoint){
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-medium;
        border-bottom: 1px solid $border-color;
        padding-bottom: 0.8rem;
        svg{
            font-size: 2rem;
            line-height: 2rem;
        }
    }
}

.dashboard_menu__item__navlink.active{
    svg{
        color: white;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        span{
            color: $primary-color;
        }
    }
}

.dashboard_topbar__full_logo{
    width: 50%;
    max-width: 140px;
}

.dashboard_menu__logo{
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    display: none;
}


.dashboard_menu__inner_top, .dashboard_menu__inner_bottom{
    width: 100%;
}

.dashboard_menu__inner_top{
    flex-grow: 1;

    @media screen and (max-width: $mobile-breakpoint){
        flex-grow: 0;
    }
}

.dashboard_menu__item, .dashboard_menu__item_logout{
    position: relative;
    display: block;
    width: 75%;
    height: 45px;
    margin: auto;
    background: none;
    border: none;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &:last-child{
        @media screen and (min-width: $mobile-breakpoint){
            margin-bottom: 0;
        }
    }

    svg{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: rgba(white,0.7);
        transition: all 0.2s ease-in-out;
    }

    &:focus{
        outline: none;
    }

    &:hover{
        .dashboard_menu__item__label{
            display: flex;
        }

        svg{
            color: white;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin: auto;
        padding: 0.75rem 0px;
        border-bottom: 1px solid $border-color;
        width: 85%;
        height: unset;
        
        .dashboard_menu__item__navlink{
            position: relative;
            display: flex;
            align-items: center;
            text-decoration: none;
            svg{
                position: relative;
                width: 30px;
                color: $primary-color;
    
            }
    
            .count_badge{
                top: 50%;
                font-size: 0.6rem;
                height: 1rem;
                width: 1rem;
                bottom: unset;
                margin-top: -0.5rem;
            }
        }

        &:last-child{
            margin-bottom: 0px;
        }
    }
}

.dashboard_menu__item__label{
    position: absolute;
    display: none;
    align-items: center;
    height: 100%;
    top: 0;
    left: 100%;
    white-space: nowrap;
    animation: fadeIn 0.3s ease;
    span{
        background-color: rgba($primary-color, 0.8);
        padding: $padding-small;
        border-radius: $border-radius-small;
        margin-left: $margin-small;
        font-size: 0.7rem;
        color: white;
        font-weight: 500;
        
    }
}

.dashboard_menu__item.active{
    svg{
        color: $charcoal;

        @media screen and (max-width: $mobile-breakpoint){
            color: darken($primary-color, 15%);
        }
    }
}

.dashboard_menu__item .count_badge{
    left: unset;
    bottom: 3px;
    right: -6px;
}
