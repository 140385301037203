.knowledge_set_page{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.knowledge_set_list_item{
    position: relative;
    display: block;
    background-color: $offwhite;
    padding: $padding-medium;
    border-radius: $border-radius-medium;
    cursor: pointer;
    text-decoration: unset;
    margin-bottom: $margin-medium;
}

.knowledge_set_page_title{
    font-size: 1.4rem;
}

.knowledge_set_page_topbar{
    display: flex;
    align-items: flex-end;
    margin-bottom: $margin-large;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: $padding-medium 0px;
}

.knowledge_set_list_item__name{
    font-size: 1.2rem;
    font-weight: bold;
}

.knowledge_set_list_item__details{
    display: flex;
    justify-content: space-between;
    margin-top: $margin-medium;

    .knowledge_set_list_item__details__item{
        @extend .timestamp;
    }
}

.index_list__title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-medium;

    .index_list__title__text{
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.index_list__body{
    font-size: 0.8rem;
    margin-bottom: $margin-medium;
    color: $grey;
}

.index_list_item{
    position: relative;
    display: block;
    padding: $padding-medium;
    cursor: pointer;
    text-decoration: unset;
    margin-bottom: $margin-small;
    border: 1px solid $border-color;
    border-radius: $border-radius-medium;
    
    &.disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:last-child{
        margin-bottom: 0px;
    }

    &:hover .index_list_item__name{
        color: $charcoal;
    }

    &.preferred_answers{
        border: 1px solid $primary-color;

        &.disabled{
            border-color: $border-color;
        }
    }
}

.index_list_item__name{
    font-size: 1.2rem;
    font-weight: 500;
    color: $charcoal;
}

.index_list_item__details__timestamp{
    display: flex;
    justify-content: flex-end;
    
    @extend .timestamp;
}

.index_list_item__details{
    display: flex;
    flex-direction: column;
    margin-top: $margin-medium;
    font-size: 0.8rem;
    font-style: italic;
}

.chunk_add_button_container{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: $padding-medium;
}

.index_chunks__search_title{
    position: sticky;
    top: 0;
    z-index: 1;
    padding: $padding-medium;
    width: 100%;
    overflow: hidden;
    text-align: center;
    flex-shrink: 0;
    font-weight: 500;
    background-color: rgba(white, 0.9);
    box-shadow: 0px 0px 5px rgba(black, 0.1);
}

.index_top_bar__search{
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    margin-right: $margin-medium;
}

.index_list_item__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-medium;
}

.index_list_item__type{
    display: flex;
    align-items: center;
    font-size: 0.8rem;

    svg{
        margin-right: 0.3rem;
        font-size: 1rem;
    }
}

.index_list_item__actions{
    display: flex;
    align-items: center;

    button{
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 100%;
    }
}

.index_search_bar{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    height: 100%;
    input{
        margin-bottom: 0px;
        max-width: 300px;
        padding-right: 40px;
    }

    .input_search_buttons{
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        .input_button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 1.2rem;
            width: 40px;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }
}

.chunk_add_button{
    display: flex;
    border: 1px solid $border-color;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-medium;
    background-color: white;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    padding: $padding-medium;
    box-sizing: border-box;

    &:hover{
        background-color: $offwhite;
    }

    svg{
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        font-size: 1.2rem;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.index_page{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: hidden;
}

.index_chunks{
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        width: 0.5em;
    }
}

.index_top_bar{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 0px 0.3rem;
    background-color: white;
    height: 60px;
    box-shadow: 0px 0px 15px rgba(black, 0.1);
    flex-shrink: 0;
}

.index_top_bar__button_group{
    display: flex;
    align-items: center;
    height: 100%;

    .index_top_bar__button, a{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0px;
        font-size: 1.3rem;
        background-color: transparent;
        border: none;
        height: 100%;
        width: 60px;
        cursor: pointer;
        transition: all 0.2s ease;
        color: $charcoal !important;
        &:hover{
            background-color: #fafafa;
        }
    }
}

.index_top_bar_title{
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: $charcoal;
    margin-left: $margin-medium;
}

.index_list{
    position: relative;
    overflow-y: auto;
    flex-grow: 1;
    padding-bottom: $padding-large;
}

.knowledge_set_page__content{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $padding-large;
    padding-bottom: 0px;
    background-color: white;
    overflow: hidden;

    .container{
        position: relative;
        height: 100%;
        overflow: hidden;
        flex-grow: 1;
    }

    .col-9{
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
    }
}

.chunk_list_item{
    position: relative;
    display: block;
    background-color: white;
    padding: 60px $padding-medium;
    border-bottom: 1px dashed $border-color;
    white-space: pre-line;
    word-break: break-word;
    cursor: pointer;
    transition: 0.1s;
    overflow: visible;
    .timestamp{
        font-size: 0.9rem;
        margin-bottom: 0.2rem;
    }
    &:hover{
        background-color: #fafafa;
    }
    
    &.active{
        top: 0;
        background-color: $offwhite;
        cursor: unset;
        padding: 40px $padding-medium;
    }

    p, li{
        font-size: 1rem !important;
    }
}

.chunk_list_item__add{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    bottom: -15px;
    left: 0;
    z-index: 22;
    transition: all 0.2s ease;

    svg{
        opacity: 0;
        font-size: 1.3rem;
        flex-shrink: 0;
    }

    &:hover{
        background-color: rgba($primary-color, 0.2);
        svg{
            opacity: 1;
        }
    
    }
}

.chunk_list_item__title{
    font-size: 1.5rem;
    margin-bottom: $margin-large;
}

.chunk_editor_format_bar{
    position: sticky;
    display: flex;
    top: 5px;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(white, 0.9);
    border-radius: $border-radius-medium;
    padding: 0.3rem;
    box-shadow: 3px 5px 15px rgba(black, 0.1);
    border: 1px solid #fafafa;
    z-index: 22;

    div{
        display: flex;
    }

    .toolbar{
        align-items: center;
    }

    button{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        height: 35px;
        width: 35px;
        font-size: 1rem;
        border: none;
        transition: all 0.2s ease;
        border-radius: $border-radius-medium;
        cursor: pointer;

        &.trash{
            color: $warning-red;
        }

        &:hover{    
            background-color: $offwhite;
        }

        &:last-child{
            border-right: none;
        }
    }
}

.chunk_editor_actions{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button{
        margin-right: 0.4rem;

        &:last-child{
            margin-right: 0px;
        }
    }
}

.chunk_list{
    position: relative;
    display: block;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: block;
}

.chunk_list__scroll{
    flex-grow: 1;
    height: fit-content !important;
}

.chunk_editor{
    ul, ol{
        margin-block-start: 0em;
        margin-block-end: 0em;
    }

    p{
        margin-block-start: 0em;
        margin-block-end: 0em;
    }

    .ql-editor{
        background-color: white;
        border-radius: $border-radius-medium;
        border: 1px solid $border-color;
        margin: $margin-medium 0px;
    }
}

.chunk_list_item{
    ul, ol{
        margin-block-start: 0em;
        margin-block-end: 0em;
    }

    p{
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
}

.tiptap {
    padding: 3px
}

.index_page__docs{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-shrink: 0;
    border-left: 1px solid $border-color;
    max-width: 40%;
}

.document_view{
    width: 100vw;
    height: 100%;
    max-width: 100%;

    iframe{
        width: 100%;
        height: 100% !important;
        outline: none;
        border: none;
    }
}

.knowledge_set_sidebar_container{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.knowledge_set_sidebar__overlay{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(black, 0.2);
}

.knowledge_set_sidebar{
    position: absolute;
    display: block;
    padding: $padding-large;
    background-color: white;
    width: 100%;
    max-width: 500px;
    right: 0;
    top: 0;
    height: 100%;
    box-shadow: -5px 0px 15px rgba(black, 0.2);
    border-right: 1px solid $border-color;
    overflow-y: auto;
    z-index: 333;
}

.knowledge_set_sidebar__title{
    position: sticky;
    display: flex;
    justify-content: space-between;
    top: -1.5rem;
    z-index: 5;
    background-color: white;
    font-size: 1.5rem;
    margin: 0px -1.5rem $margin-medium -1.5rem;
    padding: $padding-medium;
    border-bottom: 1px solid $border-color;

    button{
        display: flex;
        align-items: center;
        border: none;
        font-size: 1.4rem;
        cursor: pointer;
        background-color: transparent;
    }
}

.index_page__sidebar{
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
    height: 30px;
    flex-shrink: 0;


    button{
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        transition: all 0.2s ease;
        font-size: 0.8rem;

        &:hover{
            background-color: $offwhite;
        }
    }
}

.knowledge_set_sidebar__info_title{
    position: sticky;
    display: block;
    top: 2.5rem;
    left: 0;
    font-size: 1rem;
    margin: 0px -1.5rem 0 -1.5rem;
    padding: 0.4rem 1.5rem;
    background-color: white;
    color: $grey;
    width: calc(100% + 3rem);
    z-index: 2;
    font-weight: 500;
}

.knowledge_set_sidebar__info{
    margin-bottom: $margin-medium;
}

.knowledge_set_sidebar__info_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0px;
    font-size: 0.9rem;
}

.knowledge_set_sidebar__info_item_label{
    font-style: italic;
    color: $grey;
}

.knowledge_set_sidebar__info_item_value{
    font-weight: 500;
}

.audit_trail{
    position: relative;
    display: block;
    top: 0;
    margin: 0px -1.5rem;
}

.audit_trail_item__date_title{
    position: sticky !important;
    top: calc(3rem + 30px);
    display: block;
    background-color: white;
    padding: 0.5rem $padding-medium;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;

    &.chat_date_badge{
        font-size: 0.7rem;
        background-color: #e9f7fb;
    }
}

.audit_trail_item{
    cursor: pointer;
    padding: $padding-medium 1.5rem;
    border-bottom: 1px solid $border-color;
    transition: all 0.2s ease;
    animation: appear 0.2s ease;
    &:hover{
        background-color: $offwhite;
    }

    &.DELETE{
        .badge{ background-color: rgba($warning-red, 0.4); }
    }

    &.CREATE_INDEX{
        .badge{ background-color: rgba($online-color, 0.4); }
    }


    &.CREATE{
        .badge{ background-color: rgba($online-color, 0.4); }
    }
    
    &.PUBLISH{
        background-color: rgba($primary-color, 0.1);
        .badge{ background-color: $primary-color; color: white;}
    }

    &.SAVE_TO_UAT{
        .badge{ background-color: rgba(orange, 0.4); }
    }

    &.SAVE_TO_DRAFT{
        .badge{ background-color: rgba(purple, 0.2); }
    }
}

.audit_trail_item_meta{
    display: flex;
    justify-content: space-between;
}

.audit_trail_item__modified_by{
    display: flex;
    align-self: center;
}

.audit_trail_item_info{
    display: flex;
    align-items: center;
    margin-top: $margin-small;
    .agent_profile_avatar {
        margin-bottom: 0px;
        margin-left: 0px;
    }
}

.audit_trail_item__status{
    margin: $margin-medium 0px;

    &.badge{
        font-size: 0.6rem;
        margin-bottom: 0.2rem;
        padding: 0.2rem 0.3rem;
        margin-top: 0;
    }
}

.audit_trail_item__status__text{
    display: flex;
    font-size: 0.8rem;
    color: $grey;
    font-style: italic;
}

.knowledge_set_info_container{
    padding: 0px $padding-large;
}

.knowledge_set_info{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 450px;
    padding: $padding-large;
    overflow-y: auto;
    width: 100%;
    border-radius: $border-radius-small;
    box-shadow: 1px 1px 7.5px rgba(black, 0.1);
}

.knowledge_set_info__title{
    font-size: 1.5rem;
    margin-bottom: $margin-medium;
}

.knowledge_set_info__body{
    font-size: 0.8rem;
    color: $grey;
    margin-bottom: $margin-large;
}

.knowledge_set_info__actions{
    display: flex;
    justify-content: flex-end;
    padding-top: $padding-medium;
    button{
        margin-right: 0.5rem;

        &:last-child{
            margin-right: 0px;
        }
    }
}

.knowledge_set_info__previous_version{
    margin-bottom: $margin-medium;
    border-radius: $border-radius-medium;
    box-shadow: 1px 1px 7.5px rgba(black, 0.1);
    overflow: hidden;
}

.knowledge_set_info__previous_version__title{
    display: flex;
    align-items: center;
    padding: 0.5rem $padding-medium;
    font-size: 0.8rem;
    line-height: 0.8rem;
    background-color: rgba($online-color, 0.2);

    svg{
        font-size: 1rem;
        margin-right: 0.3rem;
    }

    &.failed{
        background-color: rgba($warning-red, 0.4)
    }
}

.knowledge_set_info_previous_version_stats{
    padding: $padding-medium;
}

.knowledge_set_info_previous_version_stat{
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
}

.knowledge_set_info_previous_version_stat__title{
    font-weight: 500;
    color: $charcoal;
}

.knowledge_set_info_previous_version_stat__value{
    color: $grey;
}

.modal_content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal_type_field{
    display: flex;
    width: 100%;
}

.modal_field{
    width: 100%;
    flex-grow: 1;
    margin-bottom: $margin-medium;
}

.modal_type_field_item{
    margin: 0px $margin-small;
    border: 1px solid $border-color;
    padding: $padding-medium;
    border-radius: $border-radius-medium;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: border-color, background-color 0.2s ease;
    flex-grow: 1;
    flex-basis: 0;

    &.active{
        border-color: $primary-color;
        color: $primary-color;
    }

    &:hover{
        background-color: $offwhite;
    }

    svg{
        flex-shrink: 0;
        font-size: 2.2rem;
        margin-right: 1rem;
    }
    &:first-child{
        margin-left: 0px;
    }

    &:last-child{
        margin-right: 0px;
    }

    .modal_type_field_item_label{
        font-weight: 500;
        margin-bottom: $margin-small;
    }

    .modal_type_field_item_description{
        font-size: 0.8rem;
        color: $grey;
    }
}

.chunk_attachment_editor{
    position: relative;
    background-color: white;
    width: 100%;
    max-width: 800px;
    border: 1px solid $border-color;
    border-radius: $border-radius-medium;
    margin-bottom: $margin-medium;
    padding: $padding-medium;

    .remove_attachment{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -7px;
        right: -7px;
        background-color: $warning-red;
        color: white;
        border: none;
        cursor: pointer;
        font-size: 0.8rem;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        z-index: 2;
        transition: all 0.2s ease;

        &:hover{
            background-color: darken($warning-red, 10%);
        }

        &:focus{
            outline: none;
        }

        &:active{
            scale: 0.9;
        }

        svg{
            flex-shrink: 0;
        }
    }

    .message_media_item{
        margin-bottom: $margin-medium;
        border-radius: $border-radius-medium;
        overflow: hidden;
    }
}

.chunk_attachments{
    display: flex;
    width: 100%;
    overflow-y: visible;
    overflow-x: auto;
    height: fit-content;
}

.chunk_attachment{
    position: relative;
    height: fit-content;
    max-width: 400px;
    padding: $padding-medium;
    border-radius: $border-radius-medium;
    flex-shrink: 0;
    margin-right: $margin-medium;   

    &:last-child{
        margin-right: 0px;
    }

    img{
        width: 400px;
        height: 200px;
        border-radius: $border-radius-medium;
        max-height: 250px;
        object-fit: contain;
    }

    .chunk_attachment_details{
        position: relative;
    }

    .chunk_attachment_details__title{
        font-weight: 700;
        margin-top: $margin-medium;
    }

    .chunk_attachment_details__description{
        font-size: 0.8rem;
        margin-top: $margin-small;
        color: $grey;
    }
}

.chunk_search_log_modal{
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 95% !important;
    height: 95%;

    .index_chunks{
        flex-grow: 1;
    }
}

.highlight{
    position: relative;
    display: inline-block;
    background-color: yellow;
    animation: highlightFocus 1s ease;
}

@keyframes highlightFocus{
    0%{ scale: 1.3;}
    90%{ scale: 1; }
}

.index_filters{
    position: absolute;
    padding: 15px;
    top: 100%;
    right: 0%;
    z-index: 222;
    background-color: white;
    box-shadow: 0px 0px 15px rgba(black, 0.1);

    label{
        text-align: right;
    }
}