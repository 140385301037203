.chat_message_bar_actions{
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    background-color: white;
}

.chat_message_bar_actions__right{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}