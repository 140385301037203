.message__prompts{
    display: flex;
    flex-flow: wrap;

    &.hero-card{
        flex-direction: column;

        .message_prompt{
            text-align: center;

            &:last-child{
                margin-right: 0px;
            }
        }
    }
}

.message_prompt{
    padding: 0.6rem $padding-medium;
    border-radius: $border-radius-large;
    font-weight: 500;
    color: $charcoal;
    background-color: white;
    color: $primary-color;
    flex-grow: 1;
    text-align: center;
    font-size: 0.8rem;
    margin: 3px;
    box-shadow: 1px 2.5px 5px rgba(black, 0.05);
}