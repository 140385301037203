.CalendarDay__hovered_span {
    background-color: #e7f0f7 !important;
    border-color: darken(#e7f0f7, 3%) !important;
    color: $primary-color !important;
}

.CalendarDay__default:hover{
    background-color: darken(#e7f0f7, 10%) !important;
    border-color: darken(#e7f0f7, 3%) !important;
    color: $primary-color !important;
}

.CalendarDay__selected_span{
    background-color: #e7f0f7 !important;
    border-color: darken(#e7f0f7, 3%) !important;
    color: $primary-color !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: white !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before{
    border-right: 33px solid $primary-color !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before{
    border-right: 33px solid darken($primary-color, 10%) !important;
}