.user_info_list{
    .chat_transfer{
        bottom: unset;
        z-index: 2;
        margin-top: $margin-small;
    }
}

.user_info_list_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color-light;
    padding: 0.5rem 0px;
    word-break: break-all;

    .message_media_item{
        margin-top: 0.3rem;
    }

    .message_media_item__fit.thumbnail{
        margin-bottom: 0px;
    }

    input{
        margin: 0px;
        border: 0px;
        padding: 0px;
        font-size: 1rem;
        line-height: 1rem;
    }

    &:last-child{
        border: none;
        padding-bottom: 0px;
    }
}

.user_info_item_value__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
    
    .user_info_item_value__action{
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-left: $margin-small;
        color: $primary-color;
    }
}

.user_info_list__actions{
    display: flex;
    justify-content: space-between;
    margin-top: $margin-medium;
    .btn{
        flex-grow: 1;
        flex-basis: 0;
        margin-right: $margin-small;

        &:last-child{
            margin-right: 0px;
        }
    }
}

.user_info_list_item__details{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.user_info_list_item__key{
    color: #777;
    font-size: 0.75rem;
    flex-shrink: 0;
}

.user_info_subtable{
    border-radius: $border-radius-small;
    margin-top: $margin-small;
    border: 1px solid rgba($primary-color, 0.3);
    td{
        padding: $padding-small;
        font-size: 0.8rem;
        font-weight: 500;
        border: 1px solid rgba($primary-color, 0.3);
        &:first-child{
            padding-right: $padding-medium;
        }
    }
    .user_info_subtable_title{
        font-style: italic;
        color: #777;
        font-weight: 400;
    }
}

.user_info_list_edit{
    display: flex;
    align-items: center;
}

.user_info_list_item__value{
    width: fit-content;
    cursor: text;
    border-radius: $border-radius-small;
    &:hover{
        background-color: $offwhite;
    }
}

.user_info_list_item__actions{
    display: flex;
    margin-left: 0.3rem;
}

.user_info_list_item__action{
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-right: 0.2rem;

    &.save{
        color: $online-color;
    }

    &.cancel{
        color: $warning-red;
    }

    &:last-child{
        margin-right: 0px;
    }
}