.alert_box{
    background-color: lighten($primary-color, 45%);
    padding: 0.8rem $padding-medium;
    font-size: 0.8rem;
    border-radius: $border-radius-small;
    color: darken($primary-color, 10%);
}

.alert_box.alert-danger{
    @extend .alert_box;
    background-color: lighten($warning-red, 25%);
    color: $grey;
}

.alert_box.alert-success{
    @extend .alert_box;
    background-color: lighten($online-color, 25%);
    color: darken($online-color, 25%);;
}

.alert_box.alert-clear{
    @extend .alert_box;
    background-color: transparent;
    color: $grey;
}

.alert_box_text_with_icon{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 0.8rem;

    svg{
        margin: 0px;
        margin-right: $margin-small;
        font-size: 1.2rem;
    }
}